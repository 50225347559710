import { useState } from 'react';
import useAuth from '../../Hooks/useAuth';
import styles from './Login.module.css';
import { POST } from '../../Services/ApiService';

export default function Login(){

    const { loginHandler } = useAuth();
    const [showPassword, setShowPassword] = useState(false);

    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage ] = useState('');
    
    const loginFormHandler = (e) => {
        e.preventDefault();
        if(userId !== '' && password !== ''){
          setLoading(true);
          POST('auth/login', {userId, password}).then(function (response) {
            //   console.log(response);
              if(response.status === 200 && response.data.status){
                loginHandler(response.data.accessToken, true, response.data.username, response.data.id, response.data.designation, response.data.infoHubId);
              }else{
                setMessage(response.data.message);
              }
              setLoading(false);
          })
          .catch(function (error) {
              console.log(error);
          });
        }else{
          setMessage('Invalid Username or Password');
        }
    }

    return(
        <div className={styles.login}>
            <div className={`d-block position-absolute top-50 start-50 translate-middle ${styles.loginCard}`}>
                <div className="card shadow border-primary">
                    <div className="card-header bg-primary ">
                        <h3 className='m-3 text-center text-light'>Login Form</h3>
                    </div>
                    <div className="card-body">
                        <form action="" className='p-3'>
                            <div className="form-floating mb-3">
                                <input type="text" className="form-control" id="userId" placeholder="Enter User Id" name='userId' value={userId} onChange={(e) => setUserId(e.target.value)} />
                                <label htmlFor="userId">Enter User Id</label>
                            </div>
                            <div className="form-floating">
                                <input type={showPassword ? 'text' : 'password'} className="form-control pe-5" id="password" placeholder="Enter Password" name='password'  value={password} onChange={(e) => setPassword(e.target.value)} />
                                <label htmlFor="password">Enter Password</label>
                                {showPassword ? <span onClick={() => setShowPassword(false)} className='position-absolute top-0 end-0 me-3 mt-3'><i className="bi bi-eye"></i></span>
                                : <span onClick={() => setShowPassword(true)} className='position-absolute top-0 end-0 me-3 mt-3'><i className="bi bi-eye-slash"></i></span>}
                            </div>
                            <div className="form-group text-end mt-4">
                                {loading ?
                                <button type="button" className="btn btn-primary" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status"></span>
                                    &nbsp;&nbsp;Logging In...
                                </button> :
                                <button disabled={userId === '' || password === ''} type="submit" className='btn btn-outline-primary' onClick={loginFormHandler}><i className="bi bi-box-arrow-in-right"></i>&nbsp;&nbsp;Login</button> }
                            </div>
                        </form>
                    </div>
                    {message !== '' ?
                    <div className="card-footer">
                        {message}
                    </div> : ''}
                </div>
            </div>
        </div>
    )
}