import { Navigate } from "react-router-dom";

export default function AuthRoute(props) {
    const localCookie = localStorage.getItem('gizUser');
    if(localCookie != null){
        if (props.url === 'login') {
            return <Navigate to="/dashboard" />;
        } else {
            return props.children;
        }
    }else{
        if (props.url !== 'login'){
            return <Navigate to="/unauthorized" />;
        } else{
            return props.children;
        }
    }
}