import { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from 'yup';
import useAuth from '../../Hooks/useAuth';
import { GET, POST } from '../../Services/ApiService';
import { informedByList, professionList } from '../../Services/Lookup';
import Loader from '../Common/Loader';

export default function ServiceSeekerAdd(){

    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const { ssid } = useParams();
    const navigate = useNavigate();
    const { userInfo } = useAuth();
    const [allServiceInformationList, setAllServiceInformationList] = useState([]);
    const [serviceInformationList, setServiceInformationList] = useState([]);
    const [serviceInformationDetails, setServiceInformationDetails] = useState(null);
    const [inputs, setInputs] = useState({
        serviceSeekerName: '',
        // infoHubId: -1,
        fatherName: '',
        motherName: '',
        gender: '',
        dob: new Date(),
        age: '',
        profession: '',
        annualIncomeLimit: '',
        handicappedFlag: '',
        widowFlag: '',
        idNumber: '',
        idType: '',
        address: '',
        unionOrThana: '',
        phoneNumber: '',
        serviceId: '',
        informedBy: '',
        comments: ''
    });

    const [inputsFilteredBy, setInputsFilteredBy] = useState({
        gender: '',
        age: '',
        profession: '',
        annualIncomeLimit: '',
        handicappedFlag: '',
        widowFlag: ''
    });

    // const serviceIdOptions = [];

    useEffect(() => {
        if(ssid){
            // console.log(ssid);
            setLoading(true);
            GET(`serviceSeeker/get?id=${ssid}`).then(response => {
                if(response.status === 200 && response.data.code === 200){
                    const serviceSeekerInfo = response.data.serviceSeekerList[0];
                    // console.log(serviceSeekerInfo);
                    setInputs({
                        serviceSeekerName: serviceSeekerInfo.serviceSeekerName,
                        infoHubId: serviceSeekerInfo.infoHubId.infoHubId,
                        fatherName: serviceSeekerInfo.fatherName,
                        motherName: serviceSeekerInfo.motherName,
                        gender: serviceSeekerInfo.gender === null ? '' : serviceSeekerInfo.gender,
                        age: serviceSeekerInfo.age,
                        profession: serviceSeekerInfo.profession === null ? '' : serviceSeekerInfo.profession,
                        annualIncomeLimit: serviceSeekerInfo.annualIncomeLimit === null ? '' : serviceSeekerInfo.annualIncomeLimit,
                        handicappedFlag: serviceSeekerInfo.handicappedFlag === null ? '' : serviceSeekerInfo.handicappedFlag,
                        widowFlag: serviceSeekerInfo.widowFlag === null ? '' : serviceSeekerInfo.widowFlag,
                        idNumber: serviceSeekerInfo.idNumber,
                        idType: serviceSeekerInfo.idType === null ? '' : serviceSeekerInfo.idType,
                        address: serviceSeekerInfo.address,
                        unionOrThana: serviceSeekerInfo.unionOrThana,
                        phoneNumber: serviceSeekerInfo.phoneNumber,
                        serviceId: serviceSeekerInfo.serviceId !== null ? serviceSeekerInfo.serviceId.serviceId : 0,
                        informedBy: serviceSeekerInfo.informedBy === null ? '' : serviceSeekerInfo.informedBy,
                        comments: serviceSeekerInfo.comments,
                        serviceSeekerId: serviceSeekerInfo.serviceSeekerId
                    });
                    setDetailsData(serviceSeekerInfo.serviceId);
                    setLoading(false);
                }
            }).catch(error => {
                console.log(error);
            });
        }

        GET('lookup/serviceInfo/all').then(response => {
            // console.log(response);
            if(response.status === 200){
                setServiceInformationList(response.data);
                setAllServiceInformationList(response.data);
            }else{
                setServiceInformationList([]);
                setAllServiceInformationList([])
            }
        }).catch(err => {
            console.log(err)
        });
    }, [ssid]);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if(value !== -1){
            setInputsFilteredBy(values => ({...values, [name]: value})); 
        }else{
            setInputsFilteredBy(values => ({...values, [name]: ''}));
        }
    }

    const setDetailsData = (serviceInfo) => {
        // console.log(serviceInfo);
        if(serviceInfo && serviceInfo !== null && serviceInfo !== -1){
            setServiceInformationDetails({
                ageLimit: serviceInfo.ageLimit !== null ? Number(serviceInfo.ageLimit) : 0,
                annualIncomeLimit: serviceInfo.annualIncomeLimit,
                associateProfession: serviceInfo.associateProfession,
                cancelReason: serviceInfo.cancelReason,
                eligibility: serviceInfo.eligibility,
                gender: serviceInfo.gender,
                handicappedFlag: serviceInfo.handicappedFlag,
                ineligibility: serviceInfo.ineligibility,
                selectionCriteria: serviceInfo.selectionCriteria,
                selectionProcess: serviceInfo.selectionProcess,
                serviceDescription: serviceInfo.serviceDescription,
                serviceName: serviceInfo.serviceName,
                serviceType: serviceInfo.serviceType,
                serviceProviderId: Number(serviceInfo.serviceProviderId),
                widowFlag: serviceInfo.widowFlag,
                serviceId: Number(serviceInfo.serviceId)
            });
        } else{
            setServiceInformationDetails(null)
        } 
    }

    useEffect(() => {
        let selectedServiceInfo = allServiceInformationList;

        // console.log(inputsFilteredBy);

        if(inputsFilteredBy.age !== '' && Number(inputsFilteredBy.age) > 0 && (inputsFilteredBy.gender === '' || inputsFilteredBy.gender === null)){
            // console.log('age logic')
            if(Number(inputsFilteredBy.age) >= 60){
                selectedServiceInfo = allServiceInformationList.filter(itemInArray => {
                    return itemInArray.ageLimit >= 60 || itemInArray.ageLimit === 0
                });
            } else if(Number(inputsFilteredBy.age) < 45){
                selectedServiceInfo = allServiceInformationList.filter(itemInArray => {
                    return itemInArray.ageLimit < 45 || itemInArray.ageLimit === 0
                });
            } else if(Number(inputsFilteredBy.age) >= 45 && Number(inputsFilteredBy.age) < 60){
                selectedServiceInfo = allServiceInformationList.filter(itemInArray => {
                    return (itemInArray.ageLimit >= 45 && itemInArray.ageLimit < 60) || itemInArray.ageLimit === 0
                });
            }
        }

        if(inputsFilteredBy.gender !== '' && inputsFilteredBy.gender != null && (inputsFilteredBy.age === '' || Number(inputsFilteredBy.age) <= 0)){
            // console.log('gender logic')
            if(Number(inputsFilteredBy.gender) === 1){
                selectedServiceInfo = allServiceInformationList.filter(itemInArray => {
                    return itemInArray.gender === 1 || itemInArray.gender === 4
                });
            } else if(Number(inputsFilteredBy.gender) === 2){
                selectedServiceInfo = allServiceInformationList.filter(itemInArray => {
                    return itemInArray.gender === 2 || itemInArray.gender === 4
                });
            } else if(Number(inputsFilteredBy.gender) === 3){
                selectedServiceInfo = allServiceInformationList.filter(itemInArray => {
                    return itemInArray.gender === 3 || itemInArray.gender === 4
                });
            } else{
                selectedServiceInfo = allServiceInformationList.filter(itemInArray => {
                    return itemInArray.gender === 1 || itemInArray.gender === 2 || itemInArray.gender === 3 || itemInArray.gender === 4
                });
            }
        }

        if(inputsFilteredBy.age !== '' && Number(inputsFilteredBy.age) > 0 && inputsFilteredBy.gender !== '' && inputsFilteredBy.gender != null){
            // console.log('age gender logic')
            if(Number(inputsFilteredBy.age) >= 60){
                selectedServiceInfo = allServiceInformationList.filter(itemInArray => {
                    return (itemInArray.ageLimit >= 60 || itemInArray.ageLimit === 0) && (itemInArray.gender === Number(inputsFilteredBy.gender) || itemInArray.gender === 4)
                });
            } else if(Number(inputsFilteredBy.age) < 45){
                selectedServiceInfo = allServiceInformationList.filter(itemInArray => {
                    return (itemInArray.ageLimit < 45 || itemInArray.ageLimit === 0) && (itemInArray.gender === Number(inputsFilteredBy.gender) || itemInArray.gender === 4)
                });
            } else if(Number(inputsFilteredBy.age) >= 45 && Number(inputsFilteredBy.age) < 60){
                selectedServiceInfo = allServiceInformationList.filter(itemInArray => {
                    return (((itemInArray.ageLimit >= 45 && itemInArray.ageLimit < 60) || itemInArray.ageLimit === 0)) && (itemInArray.gender === Number(inputsFilteredBy.gender) || itemInArray.gender === 4)
                });
            }

            if(selectedServiceInfo.length <= 0){
                if(Number(inputsFilteredBy.age) >= 60){
                    selectedServiceInfo = allServiceInformationList.filter(itemInArray => {
                        return itemInArray.ageLimit >= 60 || itemInArray.ageLimit === 0
                    });
                } else if(Number(inputsFilteredBy.age) < 45){
                    selectedServiceInfo = allServiceInformationList.filter(itemInArray => {
                        return itemInArray.ageLimit < 45 || itemInArray.ageLimit === 0
                    });
                } else if(Number(inputsFilteredBy.age) >= 45 && Number(inputsFilteredBy.age) < 60){
                    selectedServiceInfo = allServiceInformationList.filter(itemInArray => {
                        return (itemInArray.ageLimit >= 45 && itemInArray.ageLimit < 60) || itemInArray.ageLimit === 0
                    });
                }
            }

            if(selectedServiceInfo.length <= 0){
                selectedServiceInfo = allServiceInformationList.filter(itemInArray => {
                    return itemInArray.gender === Number(inputsFilteredBy.gender) || itemInArray.gender === 4
                });
            }
        }

        if(inputsFilteredBy.handicappedFlag !== '' && inputsFilteredBy.handicappedFlag != null &&
        (inputsFilteredBy.gender === '' || inputsFilteredBy.gender === null) &&
        (inputsFilteredBy.age === '' || Number(inputsFilteredBy.age) <= 0) &&
        (inputsFilteredBy.profession === '' || inputsFilteredBy.profession === null) &&
        (inputsFilteredBy.widowFlag === '' || inputsFilteredBy.widowFlag === null) &&
        (inputsFilteredBy.annualIncomeLimit === '' || Number(inputsFilteredBy.annualIncomeLimit) <= 0)){
            // console.log('handicappedFlag')
            selectedServiceInfo = allServiceInformationList.filter(itemInArray => {
                return itemInArray.handicappedFlag === Number(inputsFilteredBy.handicappedFlag)
            });
        }

        if(inputsFilteredBy.widowFlag !== '' && inputsFilteredBy.widowFlag != null &&
        (inputsFilteredBy.gender === '' || inputsFilteredBy.gender === null) &&
        (inputsFilteredBy.age === '' || Number(inputsFilteredBy.age) <= 0) &&
        (inputsFilteredBy.profession === '' || inputsFilteredBy.profession === null) &&
        (inputsFilteredBy.handicappedFlag === '' || inputsFilteredBy.handicappedFlag === null) &&
        (inputsFilteredBy.annualIncomeLimit === '' || Number(inputsFilteredBy.annualIncomeLimit) <= 0)){
            // console.log('widowFlag')
            selectedServiceInfo = allServiceInformationList.filter(itemInArray => {
                return itemInArray.widowFlag === Number(inputsFilteredBy.widowFlag)
            });
        }

        if(inputsFilteredBy.profession !== '' && inputsFilteredBy.profession != null &&
        (inputsFilteredBy.gender === '' || inputsFilteredBy.gender === null) &&
        (inputsFilteredBy.age === '' || Number(inputsFilteredBy.age) <= 0) &&
        (inputsFilteredBy.widowFlag === '' || inputsFilteredBy.widowFlag === null) &&
        (inputsFilteredBy.handicappedFlag === '' || inputsFilteredBy.handicappedFlag === null) &&
        (inputsFilteredBy.annualIncomeLimit === '' || Number(inputsFilteredBy.annualIncomeLimit) <= 0)){
            // console.log('profession')
            selectedServiceInfo = allServiceInformationList.filter(itemInArray => {
                return itemInArray.associateProfession === Number(inputsFilteredBy.profession)
            });
        }
        selectedServiceInfo = selectedServiceInfo.length > 0 ? selectedServiceInfo : allServiceInformationList;
        setServiceInformationList(selectedServiceInfo);
    },[inputsFilteredBy, allServiceInformationList]);

    return(
        <div>
            {loading ? <Loader/> : <>
                <div className="row mb-2">
                    <div className="col-12 col-md-12 text-end">
                        <Link className="btn btn-outline-secondary btn-sm rounded-0 ms-1" to="../">
                            <i className="bi bi-person-lines-fill"></i>&nbsp;&nbsp;Back to Service Seeker List
                        </Link>
                    </div>
                </div>
                <h5 className="alert alert-primary rounded-0 p-2 mb-0">Service Seeker {ssid == null ? 'Add' : 'Update'}</h5>
                <Formik
                    initialValues={inputs}
                    enableReinitialize
                    validationSchema={Yup.object({
                        serviceSeekerName: Yup.string()
                            // .matches(/^[a-zA-Z0-9.\s]+$/, 'Only Number and Character Allowed')
                            .required('Required'),
                        // fatherName: Yup.string()
                        //     .matches(/^[a-zA-Z0-9.\s]+$/, 'Only Number and Character Allowed'),
                        // motherName: Yup.string()
                        //     .matches(/^[a-zA-Z0-9.\s]+$/, 'Only Number and Character Allowed'),
                        gender: Yup.string()
                            .oneOf(['1', '2', '3', '4'], 'Invalid Selection')
                            .required('Required'),
                        age: Yup.string()
                            .matches(/^([1-9]|[1-9][0-9]|[1-9][0-9]{2})$/, 'Invalid Character!')
                            .max(150, 'Invalid Age')
                            .required('Required'),
                        idNumber: Yup.string().when("idType", {
                            is: val => (val === 1 ? true : false),
                            then: Yup.string()
                                .matches(/^([1-9][0-9]{9}|[1-9][0-9]{16})$/, 'Invalid Character! 10 or 17 Character Accepted.'),
                            otherwise: Yup.string()
                                .matches(/^[a-zA-Z0-9.\s]+$/, 'Invalid Character'),
                        }),
                        // idNumber: Yup.string()
                        //     .matches(/^[a-zA-Z0-9,-\s]+$/, 'Invalid Character'),
                        // phoneNumber: Yup.string()
                        //     .matches(/^01[3-9]\d{8}$/, 'Start with 01[3-9] and 11 Character Long'),
                        annualIncomeLimit: Yup.string()
                            .matches(/^[1-9]\d+$/, 'Only Number Allowed'),
                        serviceId: Yup.number()
                            .notOneOf(['0', 0], 'Invalid Selection')
                            .required('Required'),
                        // address: Yup.string()
                        //     .matches(/^[a-zA-Z0-9-\s]+$/, 'Invalid Character'),
                        // comments: Yup.string()
                        //     .matches(/^[a-zA-Z0-9,-\s]+$/, 'Invalid Character')
                    })}
                    onSubmit={(values) => {
                        // console.log(values);
                        // const payload = values;
                        if(values.serviceSeekerName !== '' && values.gender !== '' && values.serviceId !== '' && values.age !== ''){
                            setButtonLoading(true);
                            values.handicappedFlag = values.handicappedFlag === '' ? null : Number(values.handicappedFlag);
                            values.widowFlag = values.widowFlag === '' ? null : Number(values.widowFlag);
                            values.idType = values.idType === '' ? null : Number(values.idType);
                            values.gender = values.gender === '' ? null : Number(values.gender);
                            // values.annualIncomeLimit = values.annualIncomeLimit;
                            values.informedBy = values.informedBy === '' ? null : Number(values.informedBy);
                            values.profession = values.profession === '' ? null : Number(values.profession);
                            values.infoHubId = Number(userInfo.hubId);
                            values.serviceId = Number(values.serviceId);
                            values.age = Number(values.age);
                            // console.log(payload);
                            POST('serviceSeeker/save', values).then(function (response) {
                                if(response.status === 200 && response.data.code === 200){
                                    if(ssid){
                                        toast.success("Service Seeker Information Updated Successfully !", {
                                            position: toast.POSITION.TOP_RIGHT,
                                            autoClose: 1000,
                                            onOpen: () => {
                                                setButtonLoading(true);
                                            },
                                            onClose: () => {
                                                setButtonLoading(false);
                                            },
                                            hideProgressBar: true,
                                            closeButton: false,
                                            theme: 'dark'
                                        });
                                    }else{
                                        toast.success("Service Seeker Information Added Successfully !", {
                                            position: toast.POSITION.TOP_RIGHT,
                                            autoClose: 1000,
                                            onOpen: () => {
                                                setButtonLoading(true);
                                            },
                                            onClose: () => {
                                                setButtonLoading(false);
                                                navigate('../');
                                            },
                                            hideProgressBar: true,
                                            closeButton: false,
                                            theme: 'dark'
                                        });
                                    }
                                }
                                setButtonLoading(false);
                            }).catch(function (error) {
                                toast.error(error, {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 1000,
                                    hideProgressBar: true,
                                    closeButton: false,
                                    theme: 'dark'
                                });
                            });
                        }
                    }}
                >
                    {(formik) => {
                        const { errors, isValid, dirty } = formik;
                        return (
                            <Form>
                                <div className='row p-3'>
                                    <div className="col-12 col-md-9">
                                        <div className="form-floating mb-2">
                                            <Field className={`form-control ${errors.serviceProviderId ? 'border-danger' : 'border-warning'}`} name="serviceSeekerName" type="text" />
                                            <label htmlFor="serviceSeekerName">
                                                Service Seeker Name <span className='text-danger fw-bold'><i className="bi bi-asterisk"></i> <ErrorMessage name="serviceSeekerName" /></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <div className="form-floating mb-2">
                                            <Field name="gender">
                                                {({ field, form: { setFieldValue } }) => (
                                                    <select className={`form-select ${errors.gender ? 'border-danger' : 'border-warning'}`}
                                                        {...field}
                                                        onChange={e => {
                                                            setFieldValue('gender', e.target.value);
                                                            handleChange(e);
                                                        }}
                                                    >
                                                        <option value=''>Select Gender</option>
                                                        <option value="1">Male</option>
                                                        <option value="2">Female</option>
                                                        <option value="3">Third Gender</option>
                                                    </select>
                                                )}
                                            </Field>
                                            <label htmlFor="gender">
                                                Gender <span className='text-danger fw-bold'><i className="bi bi-asterisk"></i> <ErrorMessage name="gender" /></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <div className="form-floating mb-2">
                                            <Field name="age">
                                                {({ field, form: { setFieldValue } }) => (
                                                    <input type="text" className="form-control"
                                                        {...field}
                                                        onChange={e => {
                                                            setFieldValue('age', e.target.value);
                                                            handleChange(e);
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <label htmlFor="age">
                                                Age <span className='text-danger fw-bold'><i className="bi bi-asterisk"></i> <ErrorMessage name="age" /></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <div className="form-floating mb-2">
                                            <Field className="form-select" name="idType" as="select">
                                                <option value=''>Select Id Type</option>
                                                <option value="1">NID</option>
                                                <option value="2">Passport</option>
                                                <option value="3">Driving Licence</option>
                                                <option value="4">Birth Certificate</option>
                                            </Field>
                                            <label htmlFor="idType">Id Type</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <div className="form-floating mb-2">
                                            <Field className="form-control" name="idNumber" type="text" />
                                            <label htmlFor="idNumber">
                                                Id Number <span className='text-danger fw-bold'><ErrorMessage name="idNumber" /></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <div className="form-floating mb-2">
                                            <Field name="profession">
                                                {({ field, form: { setFieldValue } }) => (
                                                    <select className="form-select"
                                                        {...field}
                                                        onChange={e => {
                                                            setFieldValue('profession', e.target.value);
                                                            handleChange(e);
                                                        }}
                                                    >
                                                        <option value=''>Select Profession</option>
                                                        {professionList.map(prl => 
                                                            <option key={prl.value} value={Number(prl.value)}>{prl.name}</option>
                                                        )}
                                                    </select>
                                                )}
                                            </Field>
                                            <label htmlFor="profession">Profession</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <div className="form-floating mb-2">
                                            <Field name="widowFlag">
                                                {({ field, form: { setFieldValue } }) => (
                                                    <select className="form-select"
                                                        {...field}
                                                        onChange={e => {
                                                            setFieldValue('widowFlag', e.target.value);
                                                            handleChange(e);
                                                        }}
                                                    >
                                                        <option value=''>Select</option>
                                                        <option value="1">Yes</option>
                                                        <option value="2">No</option>
                                                    </select>
                                                )}
                                            </Field>
                                            <label htmlFor="widowFlag">Is Widow?</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <div className="form-floating mb-2">
                                            <Field name="handicappedFlag">
                                                {({ field, form: { setFieldValue } }) => (
                                                    <select className="form-select"
                                                        {...field}
                                                        onChange={e => {
                                                            setFieldValue('handicappedFlag', e.target.value);
                                                            handleChange(e);
                                                        }}
                                                    >
                                                        <option value=''>Select</option>
                                                        <option value="1">Yes</option>
                                                        <option value="2">No</option>
                                                    </select>
                                                )}
                                            </Field>
                                            <label htmlFor="handicappedFlag">Is Handicapped?</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <div className="form-floating mb-2">
                                            <Field className="form-control" name="phoneNumber" type="text" />
                                            <label htmlFor="phoneNumber">
                                                Phone Number <span className='text-danger fw-bold'><ErrorMessage name="phoneNumber" /></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <div className="form-floating mb-2">
                                            <Field name="annualIncomeLimit">
                                                {({ field, form: { setFieldValue } }) => (
                                                    <input type="text" className="form-control"
                                                        {...field}
                                                        onChange={e => {
                                                            setFieldValue('annualIncomeLimit', e.target.value);
                                                            handleChange(e);
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <label htmlFor="annualIncomeLimit">
                                                Annual Income <span className='text-danger fw-bold'><ErrorMessage name="annualIncomeLimit" /></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-floating mb-2">
                                            <Field className="form-control" name="fatherName" type="text" />
                                            <label htmlFor="fatherName">
                                                Father Name <span className='text-danger fw-bold'><ErrorMessage name="fatherName" /></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-floating mb-2">
                                            <Field className="form-control" name="motherName" type="text" />
                                            <label htmlFor="motherName">
                                                Mother Name <span className='text-danger fw-bold'><ErrorMessage name="motherName" /></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-floating mb-2">
                                            <Field className="form-select" name="informedBy" as="select">
                                                <option value=''>Select Informed By</option>
                                                {informedByList.map(ibl => 
                                                    <option key={ibl.value} value={Number(ibl.value)}>{ibl.name}</option>
                                                )}
                                            </Field>
                                            <label htmlFor="informedBy">Informed By</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-8">
                                        <div className="form-floating mb-2">
                                            <Field name="serviceId">
                                                {({ field, form: { setFieldValue } }) => (
                                                    <select className="form-select"
                                                        {...field}
                                                        onChange={e => {
                                                            setFieldValue('serviceId', e.target.value);
                                                            const selectedServiceInfo = serviceInformationList.find(itemInArray => itemInArray.serviceId === Number(e.target.value));
                                                            setDetailsData(selectedServiceInfo);
                                                        }}
                                                    >
                                                        <option value=''>Select Service</option>
                                                        {serviceInformationList.map(seil => 
                                                            <option key={seil.serviceId} value={Number(seil.serviceId)}>{seil.serviceName} ({seil.serviceType === 1 ? "Training" : "Non-Training"})</option>
                                                        )}
                                                    </select>
                                                )}
                                            </Field>
                                            <label htmlFor="serviceId">
                                                Service Information <span className='text-danger fw-bold'><i className="bi bi-asterisk"></i> <ErrorMessage name="serviceId" /></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-2">
                                        {serviceInformationDetails !== null ?
                                            <button type="button" className="btn btn-sm btn-dark w-100" style={{paddingTop: '6px', paddingBottom: '6px'}} data-bs-toggle="modal" data-bs-target="#detailsInfo">
                                                View Selected<br/>Service Details
                                            </button> :
                                            <button type="button" className="btn btn-sm btn-outline-dark w-100 disabled" style={{paddingTop: '6px', paddingBottom: '6px'}} disabled>
                                                No Service<br/>Selected to View
                                            </button>
                                        }
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <div className="form-floating mb-2">
                                            <Field className="form-control" name="unionOrThana" type="text" />
                                            <label htmlFor="unionOrThana">
                                                Union/Word <span className='text-danger fw-bold'><ErrorMessage name="unionOrThana" /></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-floating mb-2">
                                            <Field style={{height:'10rem'}} className="form-control" name="address" as="textarea" />
                                            <label htmlFor="address">
                                                Address <span className='text-danger fw-bold'><ErrorMessage name="address" /></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-floating mb-2">
                                            <Field style={{height:'10rem'}} className="form-control" name="comments" as="textarea" />
                                            <label htmlFor="comments">
                                                Comments <span className='text-danger fw-bold'><ErrorMessage name="comments" /></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 text-end mt-2 position-relative">
                                        <span className='position-absolute start-0 top-0 ps-3 mt-2 text-danger'><i className="bi bi-asterisk"></i> Mandatory Fields.</span>
                                        {buttonLoading ? 
                                            <button type="button" className="btn btn-primary" disabled>
                                                <span className="spinner-border spinner-border-sm me-2" role="status"></span>
                                                {ssid ? 'Updating...' : 'Saving...'}
                                            </button> :
                                            <button type="submit" className='btn btn-outline-success' disabled={!(dirty && isValid)}>
                                                {ssid ? <><i className="bi bi-save me-2"></i>Update</> : <><i className="bi bi-plus-circle me-2"></i>Add</>} Service Seeker Information
                                            </button>
                                        }
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>

                <div className="modal fade" id="detailsInfo" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Service Information Details</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                {serviceInformationDetails !== null ? 
                                    <div>
                                        <table className="table table-bordered table-striped">
                                            <tbody>
                                                <tr>
                                                    <th>Age Limit</th>
                                                    <td>{serviceInformationDetails.ageLimit}</td>
                                                    <th width="200px">Annual Income Limit</th>
                                                    <td>{serviceInformationDetails.associateProfession !== '' ? serviceInformationDetails.associateProfession : '--'}</td>
                                                </tr>
                                                <tr>
                                                    <th>For</th>
                                                    <td>{serviceInformationDetails.gender === 1 ? 'Male' : serviceInformationDetails.gender === 2 ? 'Female' : serviceInformationDetails.gender === 4 ? 'All Gender' : 'Third Gender'}</td>
                                                    <th>Associate Profession</th>
                                                    <td>{serviceInformationDetails.associateProfession}</td>
                                                </tr>
                                                <tr>
                                                    
                                                    <th>Is Handicapped?</th>
                                                    <td>{serviceInformationDetails.handicappedFlag === 1 ? 'Yes' : serviceInformationDetails.handicappedFlag === 2 ? 'No' : '--'}</td>
                                                    <th>Is Widow?</th>
                                                    <td>{serviceInformationDetails.widowFlag === 1 ? 'Yes' : serviceInformationDetails.widowFlag === 2 ? 'No' : '--'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Service Provider</th>
                                                    <td colSpan={3}>
                                                        {serviceInformationDetails.serviceProviderId !== null ? serviceInformationDetails.serviceProviderId.serviceProviderName : '--'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Service Name</th>
                                                    <td colSpan={3}>{serviceInformationDetails.serviceName}</td>
                                                </tr>
                                                <tr>
                                                    <th>Service Type</th>
                                                    <td colSpan={3}>{serviceInformationDetails.serviceType === 1 ? "Training" : "Non-Training"}</td>
                                                </tr>    
                                                <tr>
                                                    <th>Service Description</th>
                                                    <td colSpan={3}>{serviceInformationDetails.serviceDescription}</td>
                                                </tr>                                    
                                                
                                                <tr>
                                                    <th>Cancel Reason</th>
                                                    <td colSpan={3}>{serviceInformationDetails.cancelReason}</td>
                                                </tr>
                                                <tr>
                                                    <th>Eligibility</th>
                                                    <td colSpan={3}>{serviceInformationDetails.eligibility}</td>
                                                </tr>
                                                <tr>
                                                    <th>Ineligibility</th>
                                                    <td colSpan={3}>{serviceInformationDetails.ineligibility}</td>
                                                </tr>
                                                <tr>
                                                    <th>Selection Criteria</th>
                                                    <td colSpan={3}>{serviceInformationDetails.selectionCriteria}</td>
                                                </tr>
                                                <tr>
                                                    <th>Selection Process</th>
                                                    <td colSpan={3}>{serviceInformationDetails.selectionProcess}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                : "--"}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">
                                    <i className="bi bi-x-circle me-2"></i>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </div>
    )
}