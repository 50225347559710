import {useState, useEffect} from 'react';
import { useNavigate, Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { GET, POST } from '../../Services/ApiService';

export default function UserAdd(){

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [informationHubList, setInformationHubList] = useState([]);

    const [inputs, setInputs] = useState({
        designation: -1,
        infoHubId: -1,
        fullName: '',
        password: '',
        cPassword: '',
        phone: '',
        status: true,
        userActivated: true,
        username: ''
    });

    useEffect(() => {
        GET('lookup/informationHub/all').then(response => {
            console.log(response);
            if(response.status === 200){
                setInformationHubList(response.data);
            }else{
                setInformationHubList([]);
            }
        }).catch(err => {
            console.log(err)
        });
    },[]);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        console.log(event, name, value);
        if(name === 'designation' && value === 'admin'){
            setInputs(values => ({...values, infoHubId: -1}))
        }
    }

    return(
        <div>
            <div className="row mb-2">
                <div className="col-12 col-md-12 text-end">
                    <Link className="btn btn-outline-secondary btn-sm rounded-0 ms-1" to="../">
                        <i className="bi bi-person-lines-fill"></i>&nbsp;&nbsp;Back to User List
                    </Link>
                </div>
            </div>
            <h5 className="alert alert-primary rounded-0 p-2 mb-0">User Add</h5>
            <Formik
                initialValues={inputs}
                enableReinitialize
                validationSchema={Yup.object({
                    username: Yup.string()
                        .matches(/^[a-zA-Z0-9.\s]+$/, 'Only Number and Character Allowed')
                        .required('Required'),
                    // fullName: Yup.string()
                    //     .matches(/^[a-zA-Z0-9.\s]+$/, 'Only Number and Character Allowed'),
                    password: Yup.string()
                        .min(6, 'Minimum 6 Character')
                        .required('Required'),
                    cPassword: Yup.string().when("password", {
                        is: val => (val && val.length > 0 ? true : false),
                        then: Yup.string().oneOf(
                            [Yup.ref("password")],
                            "Password Didn't Match"
                        )
                    }),
                    designation: Yup.string()
                        .oneOf(['admin', 'editor', 'viewer'], 'Invalid Selection')
                        .required('Required'),
                    infoHubId: Yup.number()
                        .notOneOf([-1, 0], 'Invalid Selection')
                        .required('Required'),
                    // phone: Yup.string()
                    //     .matches(/^01[3-9]\d{8}$/, 'Start with 01[3-9] and 11 Character Long'),
                    status: Yup.string()
                        .oneOf(['true', 'false'], 'Invalid Selection')
                        .required('Required')
                })}
                onSubmit={(values) => {
                    // console.log(values);
                    if(values.username !== '' && values.password !== '' && values.password === values.cPassword && Number(values.designation) !== -1 && (values.designation === 'admin' || (values.designation !== 'admin' && Number(values.infoHubId) !== -1))){
                        setLoading(true);
                        values.infoHubId = values.designation === 'admin' ? '' : Number(values.infoHubId);
                        POST('user/add', values).then(function (response) {
                            if(response.status === 200 && response.data.code === 200){
                                toast.success("User Information Added Successfully !", {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 1000,
                                    onOpen: () => {
                                        setLoading(true);
                                    },
                                    onClose: () => {
                                        setLoading(false);
                                        navigate('../');
                                    },
                                    hideProgressBar: true,
                                    closeButton: false,
                                    theme: 'dark'
                                });
                            }
                            setLoading(false);
                        }).catch(function (error) {
                            toast.error(error, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 1000,
                                hideProgressBar: true,
                                closeButton: false,
                                theme: 'dark'
                            });
                        });
                    }
                }}
            >
                {(formik) => {
                    const { errors, isValid, dirty } = formik;
                    return (
                        <Form>
                            <div className='row p-3'>
                                <div className="col-12 col-md-3">
                                    <div className="form-floating mb-2">
                                        <Field className={`form-control ${errors.username ? 'border-danger' : 'border-warning'}`} name="username" type="text" />
                                        <label htmlFor="username">
                                            Enter Username <span className='text-danger fw-bold'><i className="bi bi-asterisk"></i> <ErrorMessage name="username" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="form-floating mb-2">
                                        <Field className="form-control" name="fullName" type="text" />
                                        <label htmlFor="fullName">
                                            Enter FullName <span className='text-danger fw-bold'><ErrorMessage name="fullName" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="form-floating mb-2">
                                        <Field className={`form-control ${errors.password ? 'border-danger' : 'border-warning'}`} name="password" type={showPassword ? 'text' : 'password'} />
                                        <label htmlFor="password">
                                            Current Password <span className='text-danger fw-bold'><i className="bi bi-asterisk"></i> <ErrorMessage name="password" /></span>
                                        </label>
                                        {showPassword ? <span onClick={() => setShowPassword(false)} className='position-absolute top-0 end-0 me-3 mt-3'><i className="bi bi-eye"></i></span>
                                        : <span onClick={() => setShowPassword(true)} className='position-absolute top-0 end-0 me-3 mt-3'><i className="bi bi-eye-slash"></i></span>}
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="form-floating mb-2">
                                        <Field className={`form-control ${errors.cPassword ? 'border-danger' : 'border-warning'}`} name="cPassword" type={showConfirmPassword ? 'text' : 'password'} />
                                        <label htmlFor="cPassword">
                                            Confirm Password <span className='text-danger fw-bold'><i className="bi bi-asterisk"></i> <ErrorMessage name="cPassword" /></span>
                                        </label>
                                        {showConfirmPassword ? <span onClick={() => setShowConfirmPassword(false)} className='position-absolute top-0 end-0 me-3 mt-3'><i className="bi bi-eye"></i></span>
                                        : <span onClick={() => setShowConfirmPassword(true)} className='position-absolute top-0 end-0 me-3 mt-3'><i className="bi bi-eye-slash"></i></span>}
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="form-floating mb-2">
                                        <Field className={`form-select ${errors.designation ? 'border-danger' : 'border-warning'}`} name="designation" as="select" onClick={(e) => handleChange(e)}>
                                            <option value="-1">Select Designation</option>
                                            <option value="admin">Admin</option>
                                            <option value="editor">Editor</option>
                                            <option value="viewer">Viewer</option>
                                        </Field>
                                        <label htmlFor="designation">
                                            Designation <span className='text-danger fw-bold'><i className="bi bi-asterisk"></i> <ErrorMessage name="designation" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="form-floating mb-2">
                                        <Field className={`form-select ${errors.infoHubId ? 'border-danger' : 'border-warning'}`} name="infoHubId" as="select">
                                            <option value="-1">Select Info Hub</option>
                                            {informationHubList.map(seil => 
                                                <option key={seil.infoHubId} value={Number(seil.infoHubId)}>{seil.infoHubName}</option>
                                            )}
                                        </Field>
                                        <label htmlFor="infoHubId">
                                            Information Hub <span className='text-danger fw-bold'><i className="bi bi-asterisk"></i> <ErrorMessage name="infoHubId" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="form-floating mb-2">
                                        <Field className="form-control" name="phone" type="text" />
                                        <label htmlFor="phone">
                                            Phone Number <span className='text-danger fw-bold'><ErrorMessage name="phone" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="form-floating mb-2">
                                        <Field className={`form-select ${errors.status ? 'border-danger' : 'border-warning'}`} name="status" as="select">
                                            <option value='true'>Active</option>
                                            <option value='false'>Inactive</option>
                                        </Field>
                                        <label htmlFor="status">
                                            Status <span className='text-danger fw-bold'><i className="bi bi-asterisk"></i> <ErrorMessage name="status" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 text-end mt-2 position-relative">
                                    <span className='position-absolute start-0 top-0 ps-3 mt-2 text-danger'><i className="bi bi-asterisk"></i> Mandatory Fields.</span>
                                    {loading ? 
                                        <button type="button" className="btn btn-primary" disabled>
                                            <span className="spinner-border spinner-border-sm me-2" role="status"></span>Saving...
                                        </button> :
                                        <button type="submit" className='btn btn-outline-success' disabled={!(dirty && isValid)}>
                                            <i className="bi bi-plus-circle me-2"></i>Add User
                                        </button>
                                    }
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    )
}