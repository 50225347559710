import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';
import useAuth from '../../Hooks/useAuth';
import { GET, POST } from '../../Services/ApiService';
import Loader from '../Common/Loader';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function ServiceSeekerList(){

    const [loading, setLoading] = useState(true);
    const [serviceSeekerList, setServiceSeekerList] = useState([]);
    const { userInfo } = useAuth();

    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalData, setTotalData] = useState(0);

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const [buttonLoading, setButtonLoading] = useState(false);
    const [allServiceInformationList, setAllServiceInformationList] = useState([]);
    const [inputs, setInputs] = useState({
        serviceId: '',
        infoHubId: 0,
        serviceSeekerName: '',
        phoneNumber: '',
        gender: 0,
        entryStartDate: null,
        entryEndDate: null
    });

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        // console.log(name, value);
        setInputs(values => ({...values, [name]: value}));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        // console.log(inputs);
        setButtonLoading(true);
        inputs.gender = Number(inputs.gender);
        inputs.infoHubId = Number(inputs.infoHubId);
        inputs.entryStartDate = getDateString(inputs.entryStartDate);
        inputs.entryEndDate = getDateString(inputs.entryEndDate);
        POST('serviceSeeker/search', inputs).then(response => {
            if(response.status === 200 && response.data.code === 200){
                setServiceSeekerList(response.data.serviceSeekerList);
                setTotalData(response.data.total);
            }else{
                setServiceSeekerList([]);
            }
            setLoading(false);
            setButtonLoading(false);
        }).catch(err => {
            console.log(err)
        });
    }

    const getDateString = (d) => {
        if(d !== '' && d !== null){
            const localDate = new Date(d);
            const day = localDate.getDate() < 10 ? '0' + localDate.getDate() : localDate.getDate();
            const real_month = Number(Number(localDate.getMonth()) + 1);
            const month = real_month < 10 ? '0' + real_month : real_month;
            const year = localDate.getFullYear();
            return `${year}-${month}-${day}`;
        }else{
            return null;
        }
    }

    const getLocalDateString = (d) => {
        if(d !== '' && d !== null){
            const localDate = new Date(d);
            const day = localDate.getDate() < 10 ? '0' + localDate.getDate() : localDate.getDate();
            const real_month = Number(Number(localDate.getMonth()) + 1);
            const month = real_month < 10 ? '0' + real_month : real_month;
            const year = localDate.getFullYear();
            return `${day}/${month}/${year}`;
        }else{
            return null;
        }
    }

    useEffect(() => {
        let hubId = null;
        // console.log('useEffect', userInfo);
        if(userInfo.type !== 'admin' && userInfo.hubId != null){
            hubId = {startIndex: currentPage, limit: pageSize, infoHubId: userInfo.hubId}
            setInputs(values => ({...values, 'infoHubId': userInfo.hubId}));
        } else{
            hubId = {startIndex: currentPage, limit: pageSize}
        }
        if(hubId != null){
            POST('serviceSeeker/search', hubId).then(response => {
                console.log('useEffect', response, hubId);
                if(response.status === 200 && response.data.code === 200){
                    setServiceSeekerList(response.data.serviceSeekerList);
                    setTotalData(response.data.total);
                }else{
                    setServiceSeekerList([]);
                }
                setLoading(false);
            }).catch(err => {
                console.log(err)
            });
        }
        GET('lookup/serviceInfo/all').then(response => {
            // console.log(response);
            if(response.status === 200){
                setAllServiceInformationList(response.data);
            }else{
                setAllServiceInformationList([])
            }
        }).catch(err => {
            console.log(err)
        });
    },[pageSize, currentPage, userInfo]);

    return(
        <div>
            {loading ? <Loader/> : <>
                {userInfo.type === 'admin' || userInfo.type === 'viewer' ? <></> : <div className="row mb-2">
                    <div className="col-12 col-md-12 text-end">
                        <Link className="btn btn-success btn-sm rounded-0 me-1" to="add">
                            <i className="bi bi-person-plus"></i>&nbsp;&nbsp;Add Service Seeker
                        </Link>
                    </div>
                </div>}
                <h5 className="alert alert-primary rounded-0 p-2">Service Seeker List</h5>
                
                <form onSubmit={handleSubmit}>
                    <div className='row p-3'>
                        <div className="col-12 col-md-2">
                            <TextField fullWidth id="outlined-basic" label="Service Seeker Name" name='serviceSeekerName' value={inputs.serviceSeekerName} onChange={e => handleChange(e)} variant="outlined" />
                        </div>
                        <div className="col-12 col-md-2">
                            <TextField fullWidth id="outlined-basic" label="Phone Number" name='phoneNumber' value={inputs.phoneNumber} onChange={e => handleChange(e)} variant="outlined" />
                        </div>
                        <div className="col-12 col-md-1">
                            <FormControl fullWidth>
                                <InputLabel id="gender">Gender</InputLabel>
                                <Select
                                    name="gender"
                                    labelId="gender"
                                    id="gender"
                                    value={inputs.gender}
                                    label="Gender"
                                    onChange={e => handleChange(e)}
                                >
                                    <MenuItem value={0}>All</MenuItem>
                                    <MenuItem value={1}>Male</MenuItem>
                                    <MenuItem value={2}>Female</MenuItem>
                                    <MenuItem value={3}>Third Gender</MenuItem>
                                </Select>
                            </FormControl>
                        </div>                                    
                        <div className="col-12 col-md-3">
                            <FormControl fullWidth>
                                <InputLabel id="serviceId">Service Information</InputLabel>
                                <Select
                                    name="serviceId"
                                    labelId="serviceId"
                                    id="serviceId"
                                    value={inputs.serviceId}
                                    label="Service Information"
                                    onChange={e => handleChange(e)}
                                >
                                    <MenuItem value="">All Service</MenuItem>
                                    {allServiceInformationList.map(seil => 
                                        <MenuItem key={seil.serviceId} value={Number(seil.serviceId)}>{seil.serviceName} ({seil.serviceType === 1 ? "Training" : "Non-Training"})</MenuItem>
                                    )}
                                    <MenuItem value={1}>Male</MenuItem>
                                    <MenuItem value={2}>Female</MenuItem>
                                    <MenuItem value={3}>Third Gender</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-12 col-md-2">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="Entry Start Date"
                                    inputFormat="dd/MM/yyyy"
                                    value={inputs.entryStartDate}
                                    maxDate={inputs.entryEndDate == null ? new Date() : new Date(inputs.entryEndDate)}
                                    onChange={(newValue) => {
                                        setInputs(values => ({...values, 'entryStartDate': newValue}));
                                    }}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="col-12 col-md-2">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="Entry End Date"
                                    inputFormat="dd/MM/yyyy"
                                    value={inputs.entryEndDate}
                                    minDate={new Date(inputs.entryStartDate)}
                                    maxDate={new Date()}
                                    onChange={(newValue) => {
                                        setInputs(values => ({...values, 'entryEndDate': newValue}));
                                    }}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="col-12 col-md-12 text-end mt-2 position-relative">
                            {buttonLoading ? 
                                <button type="button" className="btn btn-primary" disabled>
                                    <span className="spinner-border spinner-border-sm me-2" role="status"></span>
                                    Searching...
                                </button> :
                                <button type="submit" className='btn btn-outline-success'>
                                    <i className="bi bi-search me-2"></i>Search
                                </button>
                            }
                        </div>
                    </div>
                </form>
    
                <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                        <thead>
                            <tr className='bg-secondary text-light'>
                                <th>Fullname</th>
                                <th>Father Name</th>
                                <th>Mother Name</th>
                                <th>Gender</th>
                                <th>Age</th>
                                <th>Phone No.</th>
                                <th>Is Handicapped</th>
                                <th>Is Widow</th>
                                <th>Service</th>
                                <th>Entry Date</th>    
                                <th width="50"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {serviceSeekerList.length > 0 ? <>
                                {serviceSeekerList.map(seeker => <tr key={seeker.serviceSeekerId}>
                                    <td>{seeker.serviceSeekerName}</td>
                                    <td>{seeker.fatherName !== '' ? seeker.fatherName : '--' }</td>
                                    <td>{seeker.motherName !== '' ? seeker.motherName : '--' }</td>
                                    <td>{seeker.gender === 1 ? 'Male' : (seeker.gender === 2 ? 'Female' : (seeker.gender === 3 ? 'Third' : 'All')) }</td>
                                    <td>{seeker.age}</td>
                                    <td>{seeker.phoneNumber !== '' ? seeker.phoneNumber : '--' }</td>
                                    <td>{seeker.handicappedFlag === 1 ? 'Yes' : seeker.handicappedFlag === 2 ? 'No' : '--'}</td>
                                    <td>{seeker.widowFlag === 1 ? 'Yes' : seeker.widowFlag === 2 ? 'No' : '--'}</td>
                                    <td>{seeker.serviceId !== null ? seeker.serviceId.serviceName : '--'}</td>
                                    <td>{getLocalDateString(seeker.entryDate)}</td>
                                    <td className='text-center'>
                                        {userInfo.type === 'admin' || userInfo.type === 'viewer' ? '' : <Link to={`edit/${seeker.serviceSeekerId}`}><i className="bi bi-pencil-square"></i></Link>}
                                    </td>
                                </tr>
                                )}</> : <tr><td colSpan="10"><span className='text-danger'>Nothing Found!</span></td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                <TablePagination
                    component="div"
                    count={totalData}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </>}
        </div>
    )
}