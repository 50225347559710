import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';
import useAuth from '../../Hooks/useAuth';
import { GET, POST } from '../../Services/ApiService';
import Loader from '../Common/Loader';

export default function UserList(){

    const [loading, setLoading] = useState(true);
    const [userList, setUserList] = useState([]);
    const [informationHubList, setInformationHubList] = useState([]);
    const { userInfo } = useAuth();

    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalData, setTotalData] = useState(0);

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    useEffect(() => {
        POST('user/search', {startIndex: currentPage, limit: pageSize}).then(response => {
            if(response.status === 200 && response.data.code === 200){
                console.log(response)
                setUserList(response.data.userList);
                setTotalData(response.data.totalCount);
            }else{
                setUserList([]);
            }
            setLoading(false);
        }).catch(err => {
            console.log(err)
        });

        GET('lookup/informationHub/all').then(response => {
            if(response.status === 200){
                setInformationHubList(response.data);
            }else{
                setInformationHubList([]);
            }
        }).catch(err => {
            console.log(err)
        });
    },[pageSize, currentPage, userInfo]);

    const getInformationHubName = (id) => {
        let infoHubName = '-';
        if(id){
            informationHubList.forEach(itemInArray => {
                if(itemInArray.infoHubId && itemInArray.infoHubId === Number(id)){
                    infoHubName = itemInArray.infoHubName
                }
            });
            return infoHubName
        }
    }

    return(
        <div>
            {loading ? <Loader/> : <>
                {userInfo.type === 'editor' || userInfo.type === 'viewer' ? <></> : <div className="row mb-2">
                    <div className="col-12 col-md-12 text-end">
                        <Link className="btn btn-success btn-sm rounded-0 me-1" to="add">
                            <i className="bi bi-person-plus"></i>&nbsp;&nbsp;Add User
                        </Link>
                    </div>
                </div>}
                <h5 className="alert alert-primary rounded-0 p-2">User List</h5>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                        <thead>
                            <tr className='bg-secondary text-light'>
                                <th>Username</th>
                                <th>Fullname</th>
                                <th>Phone</th>
                                <th>Designation</th>
                                <th>Info Hub Id</th>
                                <th>Status</th>
                                <th width="50"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {userList.length > 0 ? <>
                                {userList.map(user =>
                                    <tr key={user.id}>
                                        <td>{user.username}</td>
                                        <td>{user.fullName}</td>
                                        <td>{user.phone}</td>
                                        <td className='text-capitalize'>{user.designation}</td>
                                        <td>{informationHubList.length > 0 ? user.infoHubId !== null ? getInformationHubName(user.infoHubId) : 'Central Hub' : '--'}</td>
                                        <td>{user.status ? <span className="text-success">Active</span> : <span className="text-danger">Inactive</span>}</td>
                                        <td className='text-center'>
                                        {userInfo.type === 'editor' || userInfo.type === 'viewer' ? '' : <Link to={`edit/${user.id}`}><i className="bi bi-pencil-square"></i></Link>}
                                        </td>
                                    </tr>
                                )}</> : <tr><td colSpan="7"><span className='text-danger'>Nothing Found!</span></td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                <TablePagination
                    component="div"
                    count={totalData}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </>}
        </div>
    )
}