import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';
import useAuth from '../../../Hooks/useAuth';
import { POST } from '../../../Services/ApiService';
import Loader from '../../Common/Loader';

export default function InformationHubList(){

    const [loading, setLoading] = useState(true);
    const [informationHubList, setInformationHubList] = useState([]);
    const { userInfo } = useAuth();

    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalData, setTotalData] = useState(0);

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    useEffect(() => {
        POST('informationHub/search', {startIndex: currentPage, limit: pageSize}).then(response => {
            console.log(response);
            if(response.status === 200 && response.data.code === 200){
                setInformationHubList(response.data.informationHubList);
                setTotalData(response.data.total);
            }else{
                setInformationHubList([]);
            }
            setLoading(false);
        }).catch(err => {
            console.log(err)
        });
    },[pageSize, currentPage, userInfo]);

    return(
        <div>
            {loading ? <Loader/> : <>
                {userInfo.type === 'editor' || userInfo.type === 'viewer' ? <></> : <div className="row mb-2">
                    <div className="col-12 col-md-12 text-end">
                        <Link className="btn btn-success btn-sm rounded-0 me-1" to="add">
                            <i className="bi bi-person-plus"></i>&nbsp;&nbsp;Add Information Hub
                        </Link>
                    </div>
                </div> }
                <h5 className="alert alert-primary rounded-0 p-2">Information Hub List</h5>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                        <thead>
                            <tr className='bg-secondary text-light'>
                                <th>Information Hub Name</th>
                                <th>Phone No</th>
                                <th>Email</th>
                                <th>Contact Person</th>
                                <th>District Name</th>
                                <th>Location</th>
                                <th>Address</th>
                                <th>Website</th>
                                <th width="50"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {informationHubList.length > 0 ? <>
                                {informationHubList.map(infohub => <tr key={infohub.infoHubId}>
                                    <td>{infohub.infoHubName}</td>
                                    <td>{infohub.phoneNumber}</td>
                                    <td>{infohub.email}</td>
                                    <td>{infohub.contactPerson}</td>
                                    <td>{infohub.districtId != null ? infohub.districtId.districtName : '--'}</td>
                                    <td>{infohub.locationName}</td>
                                    <td>{infohub.address}</td>
                                    <td>{infohub.webSite}</td>
                                    <td className='text-center'>
                                        {userInfo.type === 'editor' || userInfo.type === 'viewer' ? '' : <Link to={`edit/${infohub.infoHubId}`}><i className="bi bi-pencil-square"></i></Link>}
                                    </td>
                                </tr>
                                )}</> : <tr><td colSpan="9"><span className='text-danger'>Nothing Found!</span></td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                <TablePagination
                    component="div"
                    count={totalData}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </>}
        </div>
    )
}