import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

// Auth Context
import { AuthContext } from './Context/AuthContext';

// Protected Route
import ProtectedRoute from './Components/Common/ProtectedRoute';
// Auth Route
import AuthRoute from './Components/Common/AuthRoute';

// Component/Common
import Header from "./Components/Common/Header";
import Footer from "./Components/Common/Footer";
import NotFound from './Components/Common/NotFound';
import Unauthorized from "./Components/Common/Unauthorized";

// Component/Pages
import Dashboard from './Components/Pages/Dashboard';
import Login from "./Components/Pages/Login";
import Profile from './Components/Pages/Profile';
import UserLayout from "./Components/Pages/UserLayout";
import UserList from "./Components/Pages/UserList";
import UserAdd from "./Components/Pages/UserAdd";
import UserEdit from "./Components/Pages/UserEdit";
import ServiceSeekerLayout from './Components/Pages/ServiceSeekerLayout';
import ServiceSeekerList from './Components/Pages/ServiceSeekerList';
import ServiceSeekerAdd from './Components/Pages/ServiceSeekerAdd';

// Settings Pages
import SettingsLayout from './Components/Pages/Settings/SettingsLayout';
import InformationHubLayout from './Components/Pages/Settings/InformationHubLayout';
import InformationHubList from './Components/Pages/Settings/InformationHubList';
import InformationHubAdd from './Components/Pages/Settings/InformationHubAdd';
import ServiceInformationLayout from './Components/Pages/Settings/ServiceInformationLayout';
import ServiceInformationList from './Components/Pages/Settings/ServiceInformationList';
import ServiceInformationAdd from './Components/Pages/Settings/ServiceInformationAdd';
import ServiceProviderLayout from './Components/Pages/Settings/ServiceProviderLayout';
import ServiceProviderList from './Components/Pages/Settings/ServiceProviderList';
import ServiceProviderAdd from './Components/Pages/Settings/ServiceProviderAdd';


function App() {

    const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
    const [userInfo, setUserInfo] = useState({
        name: '',
        id: null,
        type: '',
        hubId: null
    });
    const navigate = useNavigate();

    useEffect(() => {
        const localCookie = localStorage.getItem('gizUser');
        // console.log(localCookie)
        if(localCookie != null){
            setUserIsLoggedIn(true);
            var decoded = jwt_decode(localCookie);
            // console.log(decoded);
            setUserInfo({
                name: decoded.un,
                id: decoded.uid,
                type: decoded.dn,
                hubId: decoded.hid
            });
        } else{
            setUserIsLoggedIn(false);
        }
    }, [userIsLoggedIn]);

    const logoutHandler = () => {
        // console.log('logout');
        setUserIsLoggedIn(false);
        setUserInfo({
            name: '',
            id: 0,
            type: '',
            hubId: 0
        });
        localStorage.removeItem('gizUser');
        navigate('/login');
    }

    const loginHandler = (accessToken, uState, userName, userId, userType, userHubId) => {
        localStorage.setItem('gizUser', accessToken);
        setUserIsLoggedIn(uState);
        setUserInfo({
            name: userName,
            id: userId,
            type: userType,
            hubId: userHubId
        });
        navigate('/dashboard');
        // console.log('login');
    }

    return (
        <div className="App">
            <AuthContext.Provider value={{userIsLoggedIn, userInfo, loginHandler, logoutHandler}}>
                <Header />
                <div className="main-content">
                    <Routes>
                        <Route path="/" element={<AuthRoute url={'login'}><Login /></AuthRoute>} />
                        <Route path="login" element={<AuthRoute url={'login'}><Login /></AuthRoute>} />
                        <Route path="dashboard" element={<AuthRoute url={'dashboard'}><Dashboard /></AuthRoute>} />
                        <Route path="profile" element={<Profile /> } />
                        <Route path="service-seeker" element={<AuthRoute url={'service-seeker'}><ServiceSeekerLayout /></AuthRoute>}>
                            <Route path="edit/:ssid" element={<ProtectedRoute><ServiceSeekerAdd /></ProtectedRoute>} />
                            <Route path="add" element={<ProtectedRoute><ServiceSeekerAdd /></ProtectedRoute>} />
                            <Route index element={<ServiceSeekerList />} />
                        </Route>
                        <Route path="settings" element={<AuthRoute url={'settings'}><SettingsLayout /></AuthRoute>}>
                            <Route path="service-provider" element={<ServiceProviderLayout /> }>
                                <Route path="edit/:spid" element={<ProtectedRoute><ServiceProviderAdd /></ProtectedRoute>} />
                                <Route path="add" element={<ProtectedRoute><ServiceProviderAdd /></ProtectedRoute>} />
                                <Route index element={<ServiceProviderList />} />
                            </Route>
                            <Route path="service-information" element={<ServiceInformationLayout /> }>
                                <Route path="edit/:siid" element={<ProtectedRoute><ServiceInformationAdd /></ProtectedRoute>} />
                                <Route path="add" element={<ProtectedRoute><ServiceInformationAdd /></ProtectedRoute>} />
                                <Route index element={<ServiceInformationList />} />
                            </Route>
                            <Route path="information-hub" element={<InformationHubLayout /> }>
                                <Route path="edit/:ihid" element={<ProtectedRoute><InformationHubAdd /></ProtectedRoute>} />
                                <Route path="add" element={<ProtectedRoute><InformationHubAdd /></ProtectedRoute>} />
                                <Route index element={<ProtectedRoute><InformationHubList /></ProtectedRoute>} />
                            </Route>
                        </Route>
                        <Route path="user-list" element={<AuthRoute url={'settings'}><UserLayout /></AuthRoute> }>
                            <Route path="edit/:uid" element={<ProtectedRoute><UserEdit /></ProtectedRoute>} />
                            <Route path="add" element={<ProtectedRoute><UserAdd /></ProtectedRoute>} />
                            <Route index element={<ProtectedRoute><UserList /></ProtectedRoute>} />
                        </Route>
                        <Route path="/unauthorized" element={<Unauthorized />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </div>
                <Footer />
            </AuthContext.Provider>
            <ToastContainer />        
        </div>
    );
}

export default App;
