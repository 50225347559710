import { Link, NavLink } from "react-router-dom";
import useAuth from '../../Hooks/useAuth';

export default function Header(){

    const { userIsLoggedIn, userInfo, logoutHandler } = useAuth();

    return(
        <nav className="navbar bg-dark navbar-dark navbar-expand-lg fixed-top">
            <div className="container-fluid">
                <span className="navbar-brand">
                    {userIsLoggedIn ?
                        <Link to="dashboard" className="nav-link">GIZ MIS</Link> : 
                        'GIZ MIS'
                    }
                </span>
                {userIsLoggedIn ?
                <>
                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">GIZ MIS</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                                <li className="nav-item">
                                    <NavLink className={({ isActive }) => isActive ? 'nav-link fw-bold text-info' : 'nav-link fw-bold'} to="dashboard">
                                        <i className="bi bi-display"></i>&nbsp;&nbsp;Dashboard
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className={({ isActive }) => isActive ? 'nav-link fw-bold text-info' : 'nav-link fw-bold'} to="service-seeker">
                                        <i className="bi bi-person-lines-fill"></i>&nbsp;&nbsp;Service Seeker
                                    </NavLink>
                                </li>
                                <li className="nav-item dropdown">
                                    <button className="nav-link dropdown-toggle fw-bold btn btn-link" id="offcanvasNavbarDropdown" data-bs-toggle="dropdown">
                                        <i className="bi bi-gear"></i>&nbsp;&nbsp;Settings
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li>
                                            <Link className="dropdown-item" to="settings/service-provider"><i className="bi bi-card-checklist text-success"></i>&nbsp;&nbsp;Service Provider</Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="settings/service-information"><i className="bi bi-journal-text"></i>&nbsp;&nbsp;Service Information</Link>
                                        </li>
                                        {userInfo.type === 'admin' ?
                                            <li>
                                                <Link className="dropdown-item" to="settings/information-hub"><i className="bi bi-columns-gap text-success"></i>&nbsp;&nbsp;Information Hub</Link>
                                            </li> : ''
                                        }
                                    </ul>
                                </li>
                                {userInfo.type === 'admin' ?
                                    <li className="nav-item">
                                        <NavLink className={({ isActive }) => isActive ? 'nav-link fw-bold text-info' : 'nav-link fw-bold'} to="user-list">
                                            <i className="bi bi-people"></i>&nbsp;&nbsp;Users
                                        </NavLink>
                                    </li> : ''
                                }
                                <li className="nav-item dropdown">
                                    <button className="nav-link dropdown-toggle fw-bold btn btn-link" id="offcanvasNavbarDropdown" data-bs-toggle="dropdown">
                                        <i className="bi bi-person-bounding-box"></i>&nbsp;&nbsp;{userInfo.name}
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li>
                                            <Link className="dropdown-item" to="profile"><i className="bi bi-person-circle text-success"></i>&nbsp;&nbsp;Profile</Link>
                                            </li>
                                        <li>
                                            <hr className="dropdown-divider" />
                                        </li>
                                        <li>
                                            <button className="dropdown-item" onClick={logoutHandler}><i className="bi bi-box-arrow-right text-danger"></i>&nbsp;&nbsp;Logout</button>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </> : ''}
            </div>
        </nav>
    )
}