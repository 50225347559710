import { createContext } from 'react';

const userIsLoggedIn = false;

export const AuthContext = createContext({
    userIsLoggedIn: userIsLoggedIn,
    userInfo: '',
    loginHandler: (uState, userName, userId, userType, userHubId) => {},
    logout: () => {}
});
