import { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import useAuth from '../../../Hooks/useAuth';
import { GET, POST } from '../../../Services/ApiService';
import Loader from '../../Common/Loader';
import { organizationTypeList } from '../../../Services/Lookup';

export default function ServiceProviderAdd(){

    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const { spid } = useParams();
    const navigate = useNavigate();
    const { userInfo } = useAuth();
    const [inputs, setInputs] = useState({
        address: '',
        authorizedPerson: '',
        departmentId: '',
        email: '',
        ministryId: '',
        phoneNumber: "",
        serviceProviderName: "",
        serviceProviderType: '',
        webSite: "",
        organizationType: '',
        courseName: "",
        serviceProviderId: 0,
        infoHubId: 0
    });

    const [allDepartmentList, setAllDepartmentList] = useState([]);
    // const [departmentList, setDepartmentList] = useState([]);
    const [ministryList, setMinistryList] = useState([]);

    useEffect(() => {
        // console.log(spid, userInfo);
        if(spid){
            setLoading(true);
            GET(`serviceProvider/get?id=${spid}`).then(response => {
                if(response.status === 200 && response.data.code === 200){
                    const serviceProvider = response.data.serviceProviderList[0];
                    console.log(serviceProvider);
                    setInputs({
                        address: serviceProvider.address,
                        authorizedPerson: serviceProvider.authorizedPerson,
                        departmentId: serviceProvider.departmentId === null ? '' : serviceProvider.departmentId.departmentId,
                        email: serviceProvider.email,
                        ministryId: serviceProvider.ministryId === null ? '' : serviceProvider.ministryId.ministryId,
                        phoneNumber: serviceProvider.phoneNumber,
                        serviceProviderName: serviceProvider.serviceProviderName,
                        serviceProviderType: serviceProvider.serviceProviderType === null ? '' : serviceProvider.serviceProviderType,
                        webSite: serviceProvider.webSite,
                        organizationType: serviceProvider.organizationType === null ? '' : serviceProvider.organizationType,
                        courseName: serviceProvider.courseName === null ? '' : serviceProvider.courseName,
                        serviceProviderId: Number(serviceProvider.serviceProviderId),
                        infoHubId: serviceProvider.infoHubId
                    });
                    setLoading(false);
                }
            }).catch(error => {
                console.log(error);
            });
        }

        GET('lookup/departmentInfo/all').then(response => {
            console.log(response)
            if(response.status === 200){
                setAllDepartmentList(response.data);
            }else{
                setAllDepartmentList([]);
            }
        }).catch(error => {
            console.log(error);
        });

        GET('lookup/ministryInfo/all').then(response => {
            console.log(response)
            if(response.status === 200){
                setMinistryList(response.data);
            }else{
                setMinistryList([]);
            }
        }).catch(error => {
            console.log(error);
        });
    }, [spid]);

    return(
        <div>
            {loading ? <Loader/> : <>
            <div className="row mb-2">
                <div className="col-12 col-md-12 text-end">
                    <Link className="btn btn-outline-secondary btn-sm rounded-0 ms-1" to="../">
                        <i className="bi bi-person-lines-fill"></i>&nbsp;&nbsp;Back to Service Provider List
                    </Link>
                </div>
            </div>
            <h5 className="alert alert-primary rounded-0 p-2 mb-0">Service Provider {spid ? 'Update' : 'Add'}</h5>
            <Formik
                initialValues={inputs}
                enableReinitialize
                validationSchema={Yup.object({
                    serviceProviderName: Yup.string()
                        // .matches(/^[a-zA-Z0-9.\s]+$/, 'Only Number and Character Allowed')
                        .required('Required'),
                    serviceProviderType: Yup.string()
                        .oneOf(['1', '2'], 'Invalid Selection')
                        .required('Required'),
                    authorizedPerson: Yup.string()
                        // .matches(/^[a-zA-Z0-9.\s]+$/, 'Only Number and Character Allowed')
                        .required('Required'),
                    // phoneNumber: Yup.string()
                    //     .matches(/^01[3-9]\d{8}$/, 'Start with 01[3-9] and 11 Character Long'),
                    email: Yup.string()
                        .email('Invalid Email Address'),
                    // ministryId: Yup.number()
                    //     .notOneOf([-1, 0], 'Invalid Selection'),
                    // departmentId: Yup.number()
                    //     .notOneOf([-1, 0], 'Invalid Selection'),
                    // organizationType: Yup.string()
                    //     .notOneOf([-1, 0], 'Invalid Selection'),
                    // address: Yup.string()
                    //     .matches(/^[a-zA-Z0-9-\s]+$/, 'Invalid Character'),
                    webSite: Yup.string()
                        .matches(/^[a-zA-Z0-9#@.:/\-\s]+$/, 'Invalid Character, i.e: https://www.example.com'),
                    // courseName: Yup.string()
                    //     .matches(/^[a-zA-Z0-9.\s]+$/, 'Only Number and Character Allowed'),
                })}
                onSubmit={(values) => {
                    console.log(values, userInfo);
                    if(values.serviceProviderName !== '' && values.serviceProviderType !== '' && values.authorizedPerson !== ''){
                        setButtonLoading(true);
                        values.departmentId = Number(values.departmentId) === '' ? null : Number(values.departmentId);
                        values.ministryId = Number(values.ministryId) === '' ? null : Number(values.ministryId);
                        values.organizationType = Number(values.organizationType) === '' ? null : Number(values.organizationType);
                        values.infoHubId = Number(userInfo.hubId);
                        POST('serviceProvider/save', values).then(function (response) {
                            if(response.status === 200 && response.data.code === 200){
                                if(spid){
                                    toast.success("Service Provider Updated Successfully !", {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 1000,
                                        onOpen: () => {
                                            setButtonLoading(true);
                                        },
                                        onClose: () => {
                                            setButtonLoading(false);
                                        },
                                        hideProgressBar: true,
                                        closeButton: false,
                                        theme: 'dark'
                                    });
                                }else{
                                    toast.success("Service Provider Added Successfully !", {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 1000,
                                        onOpen: () => {
                                            setButtonLoading(true);
                                        },
                                        onClose: () => {
                                            setButtonLoading(false);
                                            navigate('../');
                                        },
                                        hideProgressBar: true,
                                        closeButton: false,
                                        theme: 'dark'
                                    });
                                }
                            }
                        }).catch(function (error) {
                            toast.error(error, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 1000,
                                hideProgressBar: true,
                                closeButton: false,
                                theme: 'dark'
                            });
                        });
                    }
                }}
            >
                {(formik) => {
                    const { errors, isValid, dirty } = formik;
                    return (
                        <Form>
                            <div className='row p-3'>
                                <div className="col-12 col-md-6 col-lg-9">
                                    <div className="form-floating mb-2">
                                        <Field className={`form-control ${errors.serviceProviderName ? 'border-danger' : 'border-warning'}`} name="serviceProviderName" type="text" />
                                        <label htmlFor="serviceProviderName">
                                            Service Provider Name <span className='text-danger fw-bold'><i className="bi bi-asterisk"></i> <ErrorMessage name="serviceProviderName" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-3">
                                    <div className="form-floating mb-2">
                                        <Field className={`form-select ${errors.serviceProviderType ? 'border-danger' : 'border-warning'}`} name="serviceProviderType" as="select">
                                            <option value=''>Select Service Provider Type</option>
                                            <option value="1">Training</option>
                                            <option value="2">Non-Training</option>
                                        </Field>
                                        <label htmlFor="serviceProviderType">
                                            Service Provider Type <span className='text-danger fw-bold'><i className="bi bi-asterisk"></i> <ErrorMessage name="serviceProviderType" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-3">
                                    <div className="form-floating mb-2">
                                        <Field className="form-select" name="ministryId" as="select">
                                            <option value=''>Select Ministry</option>
                                            {ministryList.map(mist => 
                                                <option key={mist.ministryId} value={Number(mist.ministryId)}>{mist.ministryName}</option>
                                            )}
                                        </Field>
                                        <label htmlFor="ministryId">Ministry</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-3">
                                    <div className="form-floating mb-2">
                                        <Field className="form-select" name="departmentId" as="select">
                                            <option value=''>Select Department</option>
                                            {allDepartmentList.map(dept => 
                                                <option key={dept.departmentId} value={Number(dept.departmentId)}>{dept.departmentName}</option>
                                            )}
                                        </Field>
                                        <label htmlFor="departmentId">Department</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-3">
                                    <div className="form-floating mb-2">
                                        <Field className="form-select" name="organizationType" as="select">
                                            <option value=''>Select Organization Type</option>
                                            {organizationTypeList.map(otype => 
                                                <option key={otype.value} value={Number(otype.value)}>{otype.name}</option>
                                            )}
                                        </Field>
                                        <label htmlFor="organizationType">Organization Type</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 col-lg-3">
                                    <div className="form-floating mb-2">
                                        <Field className={`form-control ${errors.authorizedPerson ? 'border-danger' : 'border-warning'}`} name="authorizedPerson" type="text" />
                                        <label htmlFor="authorizedPerson">
                                            Authorized Person <span className='text-danger fw-bold'><i className="bi bi-asterisk"></i> <ErrorMessage name="authorizedPerson" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 col-lg-2">
                                    <div className="form-floating mb-2">
                                        <Field className="form-control" name="phoneNumber" type="text" />
                                        <label htmlFor="phoneNumber">
                                            Phone Number <span className='text-danger fw-bold'><ErrorMessage name="phoneNumber" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 col-lg-2">
                                    <div className="form-floating mb-2">
                                        <Field className="form-control" name="email" type="email" />
                                        <label htmlFor="email">
                                            Email Address <span className='text-danger fw-bold'><ErrorMessage name="email" /></span>
                                        </label>
                                    </div>
                                </div>                                
                                <div className="col-12 col-md-6 col-lg-5">
                                    <div className="form-floating mb-2">
                                        <Field className="form-control" name="courseName" type="text" />
                                        <label htmlFor="courseName">
                                            Course Name <span className='text-danger fw-bold'><ErrorMessage name="courseName" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-3">
                                    <div className="form-floating mb-2">
                                        <Field className="form-control" name="webSite" type="text" />
                                        <label htmlFor="webSite">
                                            Website <span className='text-danger fw-bold'><ErrorMessage name="webSite" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12">
                                    <div className="form-floating mb-2">
                                        <Field style={{height:'10rem'}} className="form-control" name="address" as="textarea" />
                                        <label htmlFor="address">
                                            Address <span className='text-danger fw-bold'><ErrorMessage name="address" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 text-end mt-2 position-relative">
                                    <span className='position-absolute start-0 top-0 ps-3 mt-2 text-danger'><i className="bi bi-asterisk"></i> Mandatory Fields.</span>
                                    {buttonLoading ? 
                                        <button type="button" className="btn btn-primary" disabled>
                                            <span className="spinner-border spinner-border-sm me-2" role="status"></span>
                                            {spid ? 'Updating...' : 'Saving...'}
                                        </button> :
                                        <button type="submit" className='btn btn-outline-success' disabled={!(dirty && isValid)}>
                                            {spid ? <><i className="bi bi-save me-2"></i>Update</> : <><i className="bi bi-plus-circle me-2"></i>Add</>} Service Provider
                                        </button>
                                    }
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
            </>}
        </div>
    )
}