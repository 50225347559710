import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';
import { organizationTypeList } from '../../../Services/Lookup';
import useAuth from '../../../Hooks/useAuth';
import { POST } from '../../../Services/ApiService';
import Loader from '../../Common/Loader';

export default function ServiceProviderList(){

    const [loading, setLoading] = useState(true);
    const [serviceProviderList, setServiceProviderList] = useState([]);
    const { userInfo } = useAuth();

    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalData, setTotalData] = useState(0);

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    useEffect(() => {
        let hubId = null;
        setLoading(true);
        if(userInfo.type !== 'admin' && userInfo.hubId != null){
            hubId = {startIndex: currentPage, limit: pageSize, infoHubId: userInfo.hubId}
        } else{
            hubId = {startIndex: currentPage, limit: pageSize}
        }
        if(hubId != null){
            POST('serviceProvider/search', hubId).then(response => {
                console.log(response);
                if(response.status === 200 && response.data.code === 200){
                    setServiceProviderList(response.data.serviceProviderList);
                    setTotalData(response.data.total);
                }else{
                    setServiceProviderList([]);
                }
                setLoading(false);
            }).catch(err => {
                console.log(err)
            });
        }
    },[pageSize, currentPage, userInfo]);

    const getOrganizationTypeName = (id) => {
        if(id !== -1 && id !== null){
            const selectedOrganizationType = organizationTypeList.find(itemInArray => itemInArray.value === Number(id));
            console.log(selectedOrganizationType);
            return selectedOrganizationType.name
        }else{
            return '--'
        }
    }

    return(
        <div>
            {loading ? <Loader/> : <>
                {userInfo.type === 'admin' || userInfo.type === 'viewer' ? <></> : <div className="row mb-2">
                    <div className="col-12 col-md-12 text-end">
                        <Link className="btn btn-success btn-sm rounded-0 me-1" to="add">
                            <i className="bi bi-person-plus"></i>&nbsp;&nbsp;Add Service Provider
                        </Link>
                    </div>
                </div>}
                <h5 className="alert alert-primary rounded-0 p-2">Service Provider List</h5>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                        <thead>
                            <tr className='bg-secondary text-light'>
                                <th>Service Provider Name</th>
                                <th>Service Provider Type</th>
                                <th>Department Name</th>
                                <th>Ministry Name</th>
                                <th>Organization Type</th>
                                <th>Authorized Person</th>
                                <th>Phone No</th>
                                <th>Email</th>
                                <th>Website</th>
                                <th width="50"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {serviceProviderList.length > 0 ? <>
                                {serviceProviderList.map(provider =>
                                    <tr key={provider.serviceProviderId}>
                                        <td>{provider.serviceProviderName}</td>
                                        <td>{provider.serviceProviderType === 1 ? 'Training' : provider.serviceProviderType === 2 ? 'Non Training' : '--'}</td>
                                        <td>{provider.departmentId != null ? provider.departmentId.departmentName : '--'}</td>
                                        <td>{provider.ministryId != null ? provider.ministryId.ministryName : '--'}</td>
                                        <td>{organizationTypeList.length > 0 ? (provider.organizationType !== null && provider.organizationType !== 0) ? getOrganizationTypeName(provider.organizationType) : '--' : '--'}</td>
                                        <td>{provider.authorizedPerson}</td>
                                        <td>{provider.phoneNumber}</td>
                                        <td>{provider.email}</td>
                                        <td>{provider.webSite}</td>
                                        <td className='text-center'>
                                            {userInfo.type === 'admin' || userInfo.type === 'viewer' ? '' : 
                                                <Link to={`edit/${provider.serviceProviderId}`}><i className="bi bi-pencil-square"></i></Link>
                                            }
                                        </td>
                                    </tr>
                                )}</> : <tr><td colSpan="10"><span className='text-danger'>Nothing Found!</span></td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                <TablePagination
                    component="div"
                    count={totalData}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </>}
        </div>
    )
}