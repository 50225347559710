import { useLocation, Navigate } from "react-router-dom";
import useAuth from '../../Hooks/useAuth';

export default function ProtectedRoute({ children }) {
    const { userInfo } = useAuth();
    let location = useLocation();
    // console.log('!location.pathname', !location.pathname.includes('information-hub'));
    if (userInfo.type === 'viewer' && (location.pathname.includes('information-hub') || location.pathname.includes('user-list'))) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
    //   console.log(userInfo, location)
      return <Navigate to="/unauthorized" state={{ from: location }} replace />;
    } else if (userInfo.type === 'editor' && (location.pathname.includes('information-hub') || location.pathname.includes('user-list'))) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        // console.log(userInfo, location)
        return <Navigate to="/unauthorized" state={{ from: location }} replace />;
    } else if (userInfo.type === 'admin' && !(location.pathname.includes('information-hub')) && !(location.pathname.includes('user-list'))) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        // console.log(userInfo, location)
        return <Navigate to="/unauthorized" state={{ from: location }} replace />;
    }
  
    return children;
  }