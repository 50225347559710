import { useState, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import useAuth from '../../Hooks/useAuth';
import { POST } from '../../Services/ApiService';

export default function Profile(){

    const [loading, setLoading] = useState(false);
    const [loadingPassword, setLoadingPassword] = useState(false);
    const { userInfo } = useAuth();
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [inputs, setInputs] = useState({
        fullName: '',
        phone: '',
        id: 0,
        username: '',
        status: true
    });

    const [passwordInputs, setPasswordInputs] = useState({
        userId: 0,
        userName: '',
        oldPassword: '',
        newPassword: '',
        cPassword: ''
    });

    useEffect(() => {
        if(userInfo.id !== 0 && userInfo.id !== null ){
            // console.log(userInfo.id);
            POST('user/search', {id: userInfo.id}).then(response => {
                // console.log(response);
                setInputs({
                    fullName: response.data.userList[0].fullName,
                    phone: response.data.userList[0].phone,
                    id: response.data.userList[0].id,
                    username: response.data.userList[0].username,
                    status: true
                });
                setPasswordInputs({
                    userId: response.data.userList[0].id,
                    userName: response.data.userList[0].username,
                    oldPassword: '',
                    newPassword: '',
                    cPassword: ''
                });
            });
        }
    }, [userInfo.id]);

    return(
        <div className="content">
            <h5 className="alert alert-primary rounded-0 p-2 mb-0">Profile Information Update</h5>
            <Formik
                initialValues={inputs}
                enableReinitialize
                validationSchema={Yup.object({
                    fullName: Yup.string()
                        .matches(/^[a-zA-Z0-9.\s]+$/, 'Only Number and Character Allowed'),
                    phone: Yup.string()
                        .matches(/^01[3-9]\d{8}$/, 'Start with 01[3-9] and 11 Character Long')
                })}
                onSubmit={(values) => {
                    console.log(values);
                    setLoading(true);
                    // console.log('update user', inputs);
                    POST('user/update', values).then(function (response) {
                        if(response.status === 200 && response.data.code === 200){
                            toast.success("Profile Information Updated Successfully !", {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 1000,
                                onOpen: () => {
                                    setLoading(true);
                                },
                                onClose: () => {
                                    setLoading(false);
                                },
                                hideProgressBar: true,
                                closeButton: false,
                                theme: 'dark'
                            });
                        }
                        setLoading(false);
                    }).catch(function (error) {
                        toast.error(error, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeButton: false,
                            theme: 'dark'
                        });
                    });
                }}
            >
                {(formik) => {
                    const { isValid, dirty } = formik;
                    return (
                        <Form>
                            <div className='row p-3'>
                                <div className="col-12 col-md-6 col-lg-8">
                                    <div className="form-floating mb-2">
                                        <Field className="form-control" name="fullName" type="text" />
                                        <label htmlFor="fullName">
                                            Full Name <span className='text-danger fw-bold'><ErrorMessage name="fullName" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 col-lg-4">
                                    <div className="form-floating mb-2">
                                        <Field className="form-control" name="phone" type="text" />
                                        <label htmlFor="phone">
                                            Phone Number <span className='text-danger fw-bold'><ErrorMessage name="phone" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 text-end mt-2 position-relative">
                                    {loading ? 
                                        <button type="button" className="btn btn-primary" disabled>
                                            <span className="spinner-border spinner-border-sm me-2" role="status"></span>Updating...
                                        </button> :
                                        <button type="submit" className='btn btn-outline-success' disabled={!(dirty && isValid)}>
                                            <i className="bi bi-save me-2"></i>Update Profile Information
                                        </button>
                                    }
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
            <hr />
            <h5 className="alert alert-primary rounded-0 p-2 mb-0">Profile Password Update</h5>
            <Formik
                initialValues={passwordInputs}
                enableReinitialize
                validationSchema={Yup.object({
                    oldPassword: Yup.string()
                        .min(6, 'Minimum 6 Character')
                        .required('Required'),
                    newPassword: Yup.string()
                        .min(6, 'Minimum 6 Character')
                        .required('Required'),
                    cPassword: Yup.string().when("newPassword", {
                        is: val => (val && val.length > 0 ? true : false),
                        then: Yup.string().oneOf(
                          [Yup.ref("newPassword")],
                          "Password Didn't Match"
                        )
                    })
                })}
                onSubmit={(values) => {
                    console.log(values);
                    if(values.newPassword !== '' && passwordInputs.newPassword === passwordInputs.cPassword){
                        setLoadingPassword(true);
                        POST('user/changePassword', values).then(function (response) {
                            if(response.status === 200 && response.data.code === 200){
                                toast.success("Profile Password Updated Successfully !", {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 1000,
                                    onOpen: () => {
                                        setLoadingPassword(true);
                                    },
                                    onClose: () => {
                                        setLoadingPassword(false);
                                    },
                                    hideProgressBar: true,
                                    closeButton: false,
                                    theme: 'dark'
                                });
                            }
                            setLoadingPassword(false);
                        }).catch(function (error) {
                            toast.error(error, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 1000,
                                hideProgressBar: true,
                                closeButton: false,
                                theme: 'dark'
                            });
                        });            
                    }
                }}
            >
                {(formik) => {
                    const { errors, isValid, dirty } = formik;
                    return (
                        <Form>
                            <div className='row p-3'>
                                <div className="col-12 col-md-4">
                                    <div className="form-floating mb-2">
                                        <Field className={`form-control ${errors.oldPassword ? 'border-danger' : 'border-warning'}`} name="oldPassword" type={showPassword ? 'text' : 'password'} />
                                        <label htmlFor="oldPassword">
                                            Current Password <span className='text-danger fw-bold'><i className="bi bi-asterisk"></i> <ErrorMessage name="oldPassword" /></span>
                                        </label>
                                        {showPassword ? <span onClick={() => setShowPassword(false)} className='position-absolute top-0 end-0 me-3 mt-3'><i className="bi bi-eye"></i></span>
                                        : <span onClick={() => setShowPassword(true)} className='position-absolute top-0 end-0 me-3 mt-3'><i className="bi bi-eye-slash"></i></span>}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-floating mb-2">
                                        <Field className={`form-control ${errors.newPassword ? 'border-danger' : 'border-warning'}`} name="newPassword" type={showNewPassword ? 'text' : 'password'} />
                                        <label htmlFor="newPassword">
                                            New Password <span className='text-danger fw-bold'><i className="bi bi-asterisk"></i> <ErrorMessage name="newPassword" /></span>
                                        </label>
                                        {showNewPassword ? <span onClick={() => setShowNewPassword(false)} className='position-absolute top-0 end-0 me-3 mt-3'><i className="bi bi-eye"></i></span>
                                        : <span onClick={() => setShowNewPassword(true)} className='position-absolute top-0 end-0 me-3 mt-3'><i className="bi bi-eye-slash"></i></span>}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-floating mb-2">
                                        <Field className={`form-control ${errors.cPassword ? 'border-danger' : 'border-warning'}`} name="cPassword" type={showConfirmPassword ? 'text' : 'password'} />
                                        <label htmlFor="cPassword">
                                            Confirm Password <span className='text-danger fw-bold'><i className="bi bi-asterisk"></i> <ErrorMessage name="cPassword" /></span>
                                        </label>
                                        {showConfirmPassword ? <span onClick={() => setShowConfirmPassword(false)} className='position-absolute top-0 end-0 me-3 mt-3'><i className="bi bi-eye"></i></span>
                                        : <span onClick={() => setShowConfirmPassword(true)} className='position-absolute top-0 end-0 me-3 mt-3'><i className="bi bi-eye-slash"></i></span>}
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 text-end mt-2 position-relative">
                                    <span className='position-absolute start-0 top-0 ps-3 mt-2 text-danger'><i className="bi bi-asterisk"></i> Mandatory Fields.</span>
                                    {loadingPassword ? 
                                        <button type="button" className="btn btn-primary" disabled>
                                            <span className="spinner-border spinner-border-sm me-2" role="status"></span>Updating...
                                        </button> :
                                        <button type="submit" className='btn btn-outline-success' disabled={!(dirty && isValid)}>
                                            <i className="bi bi-lock"></i>&nbsp;&nbsp;Change My Password
                                        </button>
                                    }
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    )
}