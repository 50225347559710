import axios from 'axios';

// ISTL Public Server
// const globalApiAddress = 'http://119.40.93.30:8090/gizapi';
// ISTL Local Server
// const globalApiAddress = 'https://113.11.21.65:80/gizapi';
const globalApiAddress = 'https://ucddigitalh.gov.bd/gizapi';
// Arun PC IP
// const globalApiAddress = 'http://192.168.10.30:8083/gizapi';

const headersOptions = {
    headers: {
        "accept": "*/*",
        "Content-Type": "application/json"
    }
};

function POST(endpoint, payload){
    const localCookie = localStorage.getItem('gizUser');
    if(localCookie != null){
        headersOptions.headers.Authorization = 'Bearer ' + localCookie;
    }
    return axios.post(`${globalApiAddress}/${endpoint}`, payload, headersOptions);
}

function GET(endpoint){
    const localCookie = localStorage.getItem('gizUser');
    if(localCookie != null){
        headersOptions.headers.Authorization = 'Bearer ' + localCookie;
    }
    return axios.get(`${globalApiAddress}/${endpoint}`, headersOptions);
}

export { POST, GET };

