import { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from "react-router-dom";
// import JoditEditor from "jodit-react";
import { toast } from 'react-toastify';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { POST, GET } from '../../../Services/ApiService';
import { professionList } from '../../../Services/Lookup';
// import TextEditor from '../../Common/TextEditor';

export default function ServiceInformationAdd(){

    // const editor = useRef(null);

    const [loading, setLoading] = useState(false);
    const { siid } = useParams();
    const navigate = useNavigate();
    const [serviceProviderList, setServiceProviderList] = useState([]);
    const [inputs, setInputs] = useState({
        ageLimit: 0,
        annualIncomeLimit: 0,
        cancelReason: "",
        associateProfession: '',
        eligibility: "",
        gender: '',
        handicappedFlag: '',
        ineligibility: "",
        selectionCriteria: "",
        selectionProcess: "",
        serviceDescription: "",
        serviceName: "",
        serviceType: '',
        serviceProviderId: '',
        widowFlag: ''
    });
    
    // const [serviceDescriptionDetails, setServiceDescriptionDetails] = useState('');
    // const [eligibility, setEligibility] = useState('');
    // const [ineligibility, setIneligibility] = useState('');

    // const setServiceDescriptionDetailsHandler = (name, content) => {
    //     // console.log(name, content)
    //     // setServiceDescriptionDetails(content);
    //     setInputs(values => ({...values, [name]: content}));
    // }

    useEffect(() => {
        if(siid){
            GET(`serviceInfo/get?id=${siid}`).then(response => {
                if(response.status === 200 && response.data.code === 200){
                    const serviceInfo = response.data.serviceInfoList[0];
                    // console.log(serviceInfo);
                    setInputs({
                        ageLimit: Number(serviceInfo.ageLimit),
                        annualIncomeLimit: Number(serviceInfo.annualIncomeLimit),
                        associateProfession: serviceInfo.associateProfession  === null ? '' : serviceInfo.associateProfession,
                        cancelReason: serviceInfo.cancelReason,
                        eligibility: serviceInfo.eligibility,
                        gender: serviceInfo.gender === null ? '' : serviceInfo.gender,
                        handicappedFlag: serviceInfo.handicappedFlag === null ? '' : serviceInfo.handicappedFlag,
                        ineligibility: serviceInfo.ineligibility,
                        selectionCriteria: serviceInfo.selectionCriteria,
                        selectionProcess: serviceInfo.selectionProcess,
                        serviceDescription: serviceInfo.serviceDescription,
                        serviceName: serviceInfo.serviceName,
                        serviceType: serviceInfo.serviceType !== null ? serviceInfo.serviceType : '',
                        serviceProviderId: serviceInfo.serviceProviderId !== null ? Number(serviceInfo.serviceProviderId.serviceProviderId) : '',
                        widowFlag: serviceInfo.widowFlag  === null ? '' : serviceInfo.widowFlag,
                        serviceId: Number(serviceInfo.serviceId)
                    });

                    // setServiceDescriptionDetails(serviceInfo.serviceDescription);
                }
            }).catch(error => {
                console.log(error);
            });
        }

        GET('lookup/serviceProvider/all').then(response => {
            console.log(response)
            if(response.status === 200){
                setServiceProviderList(response.data);
            }else{
                setServiceProviderList([]);
            }
        }).catch(error => {
            console.log(error);
        });

    }, [siid]);

    return(
        <div>
            <div className="row mb-2">
                <div className="col-12 col-md-12 text-end">
                    <Link className="btn btn-outline-secondary btn-sm rounded-0 ms-1" to="../">
                        <i className="bi bi-person-lines-fill"></i>&nbsp;&nbsp;Back to Service Information List
                    </Link>
                </div>
            </div>
            <h5 className="alert alert-primary rounded-0 p-2 mb-0">Service Information {siid ? 'Update' : 'Add'}</h5>
            <Formik
                initialValues={inputs}
                enableReinitialize
                validationSchema={Yup.object({
                    ageLimit: Yup.string()
                        .matches(/^[0-9\s]+$/, 'Only Number Allowed'),
                    annualIncomeLimit: Yup.string()
                        .matches(/^[0-9\s]+$/, 'Only Number Allowed'),
                    gender: Yup.string()
                        .oneOf(['1', '2', '3', '4'], 'Invalid Selection')
                        .required('Required'),
                    serviceType: Yup.string()
                        .oneOf(['1', '2'], 'Invalid Selection')
                        .required('Required'),
                    serviceProviderId: Yup.number()
                        .notOneOf(['', 0], 'Invalid Selection')
                        .required('Required'),
                    serviceName: Yup.string()
                        .required('Required'),
                        // .matches(/^[a-zA-Z0-9-\s]+$/, 'Invalid Character'),
                    // eligibility: Yup.string()
                    //     .matches(/^[a-zA-Z0-9-\s]+$/, 'Invalid Character'),
                    // ineligibility: Yup.string()
                    //     .matches(/^[a-zA-Z0-9-\s]+$/, 'Invalid Character'),
                    // selectionCriteria: Yup.string()
                    //     .matches(/^[a-zA-Z0-9-\s]+$/, 'Invalid Character'),
                    // selectionProcess: Yup.string()
                    //     .matches(/^[a-zA-Z0-9-\s]+$/, 'Invalid Character'),
                    // serviceDescription: Yup.string()
                    //     .matches(/^[a-zA-Z0-9-\s]+$/, 'Invalid Character'),
                    // cancelReason: Yup.string()
                    //     .matches(/^[a-zA-Z0-9-\s]+$/, 'Invalid Character'),
                })}
                onSubmit={(values) => {
                    console.log(values);
                    // console.log('serviceDescriptionDetails', serviceDescriptionDetails)
                    if(values.serviceName !== '' && values.serviceType !== '' && values.serviceProviderId !== '' && values.gender !== ''){
                        setLoading(true);
                        values.handicappedFlag = values.handicappedFlag === '' ? null : Number(values.handicappedFlag);
                        values.widowFlag = values.widowFlag === '' ? null : Number(values.widowFlag);
                        values.associateProfession = values.associateProfession === '' ? null : Number(values.associateProfession);
                        values.gender = values.gender === '' ? null : Number(values.gender);
                        values.serviceProviderId = Number(values.serviceProviderId);
                        POST('serviceInfo/save', values).then(function (response) {
                            if(response.status === 200 && response.data.code === 200){
                                if(siid){
                                    toast.success("Service Information Updated Successfully !", {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 1000,
                                        onOpen: () => {
                                            setLoading(true);
                                        },
                                        onClose: () => {
                                            setLoading(false);
                                        },
                                        hideProgressBar: true,
                                        closeButton: false,
                                        theme: 'dark'
                                    });
                                }else{
                                    toast.success("Service Information Added Successfully !", {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 1000,
                                        onOpen: () => {
                                            setLoading(true);
                                        },
                                        onClose: () => {
                                            setLoading(false);
                                            navigate('../');
                                        },
                                        hideProgressBar: true,
                                        closeButton: false,
                                        theme: 'dark'
                                    });
                                }
                            }
                        }).catch(function (error) {
                            toast.error(error, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 1000,
                                hideProgressBar: true,
                                closeButton: false,
                                theme: 'dark'
                            });
                        });
                    }
                }}
            >
                {(formik) => {
                    const { errors, isValid, dirty } = formik;
                    return (
                        <Form>
                            <div className='row p-3'>
                                <div className="col-12 col-md-2">
                                    <div className="form-floating mb-2">
                                        <Field className={`form-select ${errors.gender ? 'border-danger' : 'border-warning'}`} name="gender" as="select">
                                            <option value=''>Select Gender</option>
                                            <option value="4">All</option>
                                            <option value="1">Male</option>
                                            <option value="2">Female</option>
                                            <option value="3">Third Gender</option>
                                        </Field>
                                        <label htmlFor="gender">
                                            Gender <span className='text-danger fw-bold'><i className="bi bi-asterisk"></i> <ErrorMessage name="gender" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2">
                                    <div className="form-floating mb-2">
                                        <Field className="form-select" name="associateProfession" as="select">
                                            <option value=''>Select Profession</option>
                                            {professionList.map(prl => 
                                                <option key={prl.value} value={Number(prl.value)}>{prl.name}</option>
                                            )}
                                        </Field>
                                        <label htmlFor="associateProfession">Profession</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2">
                                    <div className="form-floating mb-2">
                                        <Field className="form-control" name="ageLimit" type="text" />
                                        <label htmlFor="ageLimit">
                                            Age Limit <span className='text-danger fw-bold'><ErrorMessage name="ageLimit" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2">
                                    <div className="form-floating mb-2">
                                        <Field className="form-control" name="annualIncomeLimit" type="text" />
                                        <label htmlFor="annualIncomeLimit">
                                            Annual Income Limit <span className='text-danger fw-bold'><ErrorMessage name="annualIncomeLimit" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2">
                                    <div className="form-floating mb-2">
                                        <Field className="form-select" name="widowFlag" as="select">
                                            <option value=''>Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                        </Field>
                                        <label htmlFor="widowFlag">Is Widow?</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2">
                                    <div className="form-floating mb-2">
                                        <Field className="form-select" name="handicappedFlag" as="select">
                                            <option value=''>Select</option>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                        </Field>
                                        <label htmlFor="handicappedFlag">Is Handicapped?</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-floating mb-2">
                                        <Field className={`form-select ${errors.serviceProviderId ? 'border-danger' : 'border-warning'}`} name="serviceProviderId" as="select">
                                            <option value=''>Select Service Provider</option>
                                            {serviceProviderList.map(sprl => 
                                                <option key={sprl.serviceProviderId} value={Number(sprl.serviceProviderId)}>{sprl.serviceProviderName}</option>
                                            )}
                                        </Field>
                                        <label htmlFor="serviceProviderId">
                                            Service Provider <span className='text-danger fw-bold'><i className="bi bi-asterisk"></i> <ErrorMessage name="serviceProviderId" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-floating mb-2">
                                        <Field className={`form-control ${errors.serviceName ? 'border-danger' : 'border-warning'}`} name="serviceName" type="text" />
                                        <label htmlFor="serviceName">
                                            Service Name <span className='text-danger fw-bold'><i className="bi bi-asterisk"></i> <ErrorMessage name="serviceName" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-floating mb-2">
                                        <Field className={`form-select ${errors.serviceType ? 'border-danger' : 'border-warning'}`} name="serviceType" as="select">
                                            <option value=''>Select Service Type</option>
                                            <option value="1">Training</option>
                                            <option value="2">Non-Training</option>
                                        </Field>
                                        <label htmlFor="serviceType">
                                            Service Type <span className='text-danger fw-bold'><i className="bi bi-asterisk"></i> <ErrorMessage name="serviceType" /></span>
                                        </label>
                                    </div>
                                </div>
                                {/* <div className="col-12 col-md-12">
                                    <TextEditor value={inputs.serviceDescription} setValue={content => setServiceDescriptionDetailsHandler('serviceDescription', content)} />
                                </div> */}
                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-2">
                                        <Field style={{height:'10rem'}} className="form-control" name="serviceDescription" as="textarea" />
                                        <label htmlFor="serviceDescription">
                                            Service Description <span className='text-danger fw-bold'><ErrorMessage name="serviceDescription" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-2">
                                        <Field style={{height:'10rem'}} className="form-control" name="eligibility" as="textarea" />
                                        <label htmlFor="eligibility">
                                            Eligibility <span className='text-danger fw-bold'><ErrorMessage name="eligibility" /></span>
                                        </label>
                                    </div>
                                </div>
                                {/* <div className="col-12 col-md-6">
                                    <div className="form-floating mb-2">
                                        <Field style={{height:'10rem'}} className="form-control" name="eligibility" >
                                        {({ field, form: { setFieldValue } }) => (
                                            <JoditEditor {...field} ref={editor} onChange={editor => {
                                                setFieldValue('eligibility', editor.target.value);
                                            }} />
                                        )}
                                        </Field>
                                    </div>
                                </div> */}
                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-2">
                                        <Field style={{height:'10rem'}} className="form-control" name="ineligibility" as="textarea" />
                                        <label htmlFor="ineligibility">
                                            Ineligibility <span className='text-danger fw-bold'><ErrorMessage name="ineligibility" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-2">
                                        <Field style={{height:'10rem'}} className="form-control" name="selectionProcess" as="textarea" />
                                        <label htmlFor="selectionProcess">
                                            Selection Process <span className='text-danger fw-bold'><ErrorMessage name="selectionProcess" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-2">
                                        <Field style={{height:'10rem'}} className="form-control" name="selectionCriteria" as="textarea" />
                                        <label htmlFor="selectionCriteria">
                                            Selection Criteria <span className='text-danger fw-bold'><ErrorMessage name="selectionCriteria" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-2">
                                        <Field style={{height:'10rem'}} className="form-control" name="cancelReason" as="textarea" />
                                        <label htmlFor="cancelReason">
                                            Cancel Reason <span className='text-danger fw-bold'><ErrorMessage name="cancelReason" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 text-end mt-2 position-relative">
                                    <span className='position-absolute start-0 top-0 ps-3 mt-2 text-danger'><i className="bi bi-asterisk"></i> Mandatory Fields.</span>
                                    {loading ? 
                                        <button type="button" className="btn btn-primary" disabled>
                                            <span className="spinner-border spinner-border-sm me-2" role="status"></span>
                                            {siid ? 'Updating...' : 'Saving...'}
                                        </button> :
                                        <button type="submit" className='btn btn-outline-success' disabled={!(dirty && isValid)}>
                                            {siid ? <><i className="bi bi-save me-2"></i>Update</> : <><i className="bi bi-plus-circle me-2"></i>Add</>} Service Information
                                        </button>
                                    }
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    )
}