import { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { GET, POST } from '../../../Services/ApiService';

export default function InformationHubAdd(){

    const [loading, setLoading] = useState(false);
    const { ihid } = useParams();
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({
        address: '',
        contactPerson: '',
        districtId: -1,
        email: '',
        phoneNumber: "",
        infoHubName: "",
        locationName: "",
        webSite: ""
    });
    const [districtList, setDistrictList] = useState([]);

    useEffect(() => {
        if(ihid){
            GET(`informationHub/get?id=${ihid}`).then(response => {
                if(response.status === 200 && response.data.code === 200){
                    const informationHub = response.data.informationHubList[0];
                    // console.log(informationHub);
                    setInputs({
                        address: informationHub.address,
                        contactPerson: informationHub.contactPerson,
                        districtId: informationHub.districtId == null ? -1 : informationHub.districtId.districtId,
                        email: informationHub.email,
                        phoneNumber: informationHub.phoneNumber,
                        infoHubName: informationHub.infoHubName,
                        locationName: informationHub.locationName,
                        webSite: informationHub.webSite,
                        infoHubId: Number(informationHub.infoHubId)
                    });
                    // console.log(inputs)
                }
            }).catch(error => {
                console.log(error);
            });
        }

        GET('lookup/district/all').then(response => {
            // console.log(response)
            if(response.status === 200){
                setDistrictList(response.data);
            }else{
                setDistrictList([]);
            }
        }).catch(error => {
            console.log(error);
        });
    }, [ihid]);

    return(
        <div>
            <div className="row mb-2">
                <div className="col-12 col-md-12 text-end">
                    <Link className="btn btn-outline-secondary btn-sm rounded-0 ms-1" to="../">
                        <i className="bi bi-person-lines-fill"></i>&nbsp;&nbsp;Back to Information Hub List
                    </Link>
                </div>
            </div>
            <h5 className="alert alert-primary rounded-0 p-2 mb-0">Information Hub {ihid ? 'Update' : 'Add'}</h5>
            <Formik
                initialValues={inputs}
                enableReinitialize
                validationSchema={Yup.object({
                    infoHubName: Yup.string()
                        // .matches(/^[a-zA-Z0-9.\s]+$/, 'Only Number and Character Allowed')
                        .required('Required'),
                    // phoneNumber: Yup.string()
                    //     .matches(/^01[3-9]\d{8}$/, 'Start with 01[3-9] and 11 Character Long'),
                    email: Yup.string()
                        // .matches(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, 'Invalid Email Address')
                        .email('Invalid Email Address'),
                    contactPerson: Yup.string()
                        // .matches(/^[a-zA-Z0-9.\s]+$/, 'Only Number and Character Allowed')
                        .required('Required'),
                    locationName: Yup.string()
                        // .matches(/^[a-zA-Z0-9-\s]+$/, 'Invalid Character')
                        .required('Required'),
                    // address: Yup.string()
                    //     .matches(/^[a-zA-Z0-9-\s]+$/, 'Invalid Character'),
                    webSite: Yup.string()
                        .matches(/^[a-zA-Z0-9-\s]+$/, 'Invalid Character')
                })}
                onSubmit={(values) => {
                    if(values.infoHubName !== '' && values.locationName !== '' && values.contactPerson !== ''){
                        setLoading(true);
                        values.districtId = values.districtId !== -1 ? values.districtId : '';
                        POST('informationHub/save', values).then(function (response) {
                            if(response.status === 200 && response.data.code === 200){
                                if(ihid){
                                    toast.success("Information Hub Updated Successfully !", {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 1000,
                                        onOpen: () => {
                                            setLoading(true);
                                        },
                                        onClose: () => {
                                            setLoading(false);
                                        },
                                        hideProgressBar: true,
                                        closeButton: false,
                                        theme: 'dark'
                                    });
                                }else{
                                    toast.success("Information Hub Added Successfully !", {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 1000,
                                        onOpen: () => {
                                            setLoading(true);
                                        },
                                        onClose: () => {
                                            setLoading(false);
                                            navigate('../');
                                        },
                                        hideProgressBar: true,
                                        closeButton: false,
                                        theme: 'dark'
                                    });
                                }
                            }
                        }).catch(function (error) {
                            toast.error(error, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 1000,
                                hideProgressBar: true,
                                closeButton: false,
                                theme: 'dark'
                            });
                        });
                    }
                }}
            >
                {(formik) => {
                    const { errors, isValid, dirty } = formik;
                    return (
                        <Form>
                            <div className='row p-3'>
                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-2">
                                        <Field className={`form-control ${errors.serviceProviderId ? 'border-danger' : 'border-warning'}`} name="infoHubName" type="text" />
                                        <label htmlFor="infoHubName">
                                            Information Hub Name <span className='text-danger fw-bold'><i className="bi bi-asterisk"></i> <ErrorMessage name="infoHubName" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-2">
                                        <Field className="form-select" name="districtId" as="select">
                                            <option value={-1}>Select District</option>
                                            {districtList.map(dept => 
                                                <option key={dept.districtId} value={Number(dept.districtId)}>{dept.districtName}</option>
                                            )}
                                        </Field>
                                        <label htmlFor="districtId">District</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-floating mb-2">
                                        <Field className={`form-control ${errors.contactPerson ? 'border-danger' : 'border-warning'}`} name="contactPerson" type="text" />
                                        <label htmlFor="contactPerson">
                                            Contact Person <span className='text-danger fw-bold'><i className="bi bi-asterisk"></i> <ErrorMessage name="contactPerson" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-floating mb-2">
                                        <Field className="form-control" name="phoneNumber" type="text" />
                                        <label htmlFor="phoneNumber">
                                            Phone Number <span className='text-danger fw-bold'><ErrorMessage name="phoneNumber" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-floating mb-2">
                                        <Field className="form-control" name="email" type="email" />
                                        <label htmlFor="email">
                                            Email Address <span className='text-danger fw-bold'><ErrorMessage name="email" /></span>
                                        </label>
                                    </div>
                                </div>                                
                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-2">
                                        <Field className={`form-control ${errors.locationName ? 'border-danger' : 'border-warning'}`} name="locationName" type="text" />
                                        <label htmlFor="locationName">
                                            Location Name <span className='text-danger fw-bold'><i className="bi bi-asterisk"></i> <ErrorMessage name="locationName" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-floating mb-2">
                                        <Field className="form-control" name="webSite" type="text" />
                                        <label htmlFor="webSite">
                                            Website <span className='text-danger fw-bold'><ErrorMessage name="webSite" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12">
                                    <div className="form-floating mb-2">
                                        <Field style={{height:'10rem'}} className="form-control" name="address" as="textarea" />
                                        <label htmlFor="address">
                                            Address <span className='text-danger fw-bold'><ErrorMessage name="address" /></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 text-end mt-2 position-relative">
                                    <span className='position-absolute start-0 top-0 ps-3 mt-2 text-danger'><i className="bi bi-asterisk"></i> Mandatory Fields.</span>
                                    {loading ? 
                                        <button type="button" className="btn btn-primary" disabled>
                                            <span className="spinner-border spinner-border-sm me-2" role="status"></span>
                                            {ihid ? 'Updating...' : 'Saving...'}
                                        </button> :
                                        <button type="submit" className='btn btn-outline-success' disabled={!(dirty && isValid)}>
                                            {ihid ? <><i className="bi bi-save me-2"></i>Update</> : <><i className="bi bi-plus-circle me-2"></i>Add</>} Information Hub
                                        </button>
                                    }
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    )
}