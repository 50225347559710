const informedByList = [
    {value: 1, name: "Bimonthly Meeting"},
    {value: 2, name: "Councilor"},
    {value: 3, name: "CDC"},
    {value: 4, name: "Union Porishad Member"},
    {value: 5, name: "Union Porishad Chairman"},
    {value: 6, name: "Social Worker"},
    {value: 7, name: "Social Service Office"},
    {value: 8, name: "Relative"},
    {value: 9, name: "Leaflet"},
    {value: 10, name: "Miking"},
    {value: 11, name: "News Paper"},
    {value: 12, name: "Teacher"},
    {value: 13, name: "Acquaintance"},
    {value: 14, name: "In Person"},
    // {value: 15, name: "Others"}
];

const professionList = [
    {value: 1, name: "Govt. Service"},
    {value: 2, name: "Retired Govt. Service"},
    {value: 3, name: "Private Service"},
    {value: 4, name: "Retired Private Service"},
    {value: 5, name: "Unemployed"},
    {value: 6, name: "Beggar"},
    {value: 7, name: "Laborer"},
    {value: 8, name: "Maidservant"},
    {value: 9, name: "Vagrant"},
    {value: 10, name: "Student"},
    {value: 11, name: "Tea-Worker"},
    {value: 12, name: "Housewife"},
    // {value: 13, name: "Others"}
];

const organizationTypeList = [
    {value: 1, name: "সরকারী"},
    {value: 2, name: "ব্যক্তিগত"},
    {value: 3, name: "এনজিও"},
    {value: 4, name: "কারিতাস বাংলাদেশ ট্রাস্ট"},
    {value: 5, name: "বেসরকারী"},
    {value: 6, name: "উপ-আনুষ্ঠানিক"},
    {value: 7, name: "পাবলিক"},
    {value: 8, name: "স্বাস্থ্য ইন্সটিটিউট"},
    {value: 9, name: "আইটি ট্রেনিং এন্ড গ্রাফিক্স ডিজাইন"},
    {value: 10, name: "স্বায়ত্বশাসিত"},
    // {value: 11, name: "অন্যান্য"}
];

export {informedByList, professionList, organizationTypeList};