import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';
import useAuth from '../../../Hooks/useAuth';
import { POST } from '../../../Services/ApiService';
import Loader from '../../Common/Loader';
import { professionList } from '../../../Services/Lookup';

export default function ServiceInformationList(){

    const [loading, setLoading] = useState(true);
    const [serviceInformationList, setServiceInformationList] = useState([]);
    const [serviceInformationDetails, setServiceInformationDetails] = useState(null);
    const { userInfo } = useAuth();

    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalData, setTotalData] = useState(0);

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    useEffect(() => {
        POST('serviceInfo/search', {startIndex: currentPage, limit: pageSize}).then(response => {
            // console.log(response);
            if(response.status === 200 && response.data.code === 200){
                setServiceInformationList(response.data.serviceInfoList);
                setTotalData(response.data.total);
            }else{
                setServiceInformationList([]);
            }
            setLoading(false);
        }).catch(err => {
            console.log(err)
        });
    },[pageSize, currentPage, userInfo]);

    const setDetailsData = (serviceInfo) => {
        // console.log(serviceInfo);
        setServiceInformationDetails({
            ageLimit: Number(serviceInfo.ageLimit),
            annualIncomeLimit: Number(serviceInfo.annualIncomeLimit),
            associateProfession: serviceInfo.associateProfession,
            cancelReason: serviceInfo.cancelReason,
            eligibility: serviceInfo.eligibility,
            gender: serviceInfo.gender,
            handicappedFlag: serviceInfo.handicappedFlag,
            ineligibility: serviceInfo.ineligibility,
            selectionCriteria: serviceInfo.selectionCriteria,
            selectionProcess: serviceInfo.selectionProcess,
            serviceDescription: serviceInfo.serviceDescription,
            serviceName: serviceInfo.serviceName,
            serviceType: serviceInfo.serviceType,
            serviceProviderId: serviceInfo.serviceProviderId,
            widowFlag: serviceInfo.widowFlag,
            serviceId: serviceInfo.serviceId
        });
    }

    const getAssiciatedProfessionName = (id) => {
        if(id){
            const selectedProfession = professionList.find(itemInArray => itemInArray.value === Number(id));
            return selectedProfession.name
        }
    }

    return(
        <div>
            {loading ? <Loader/> : <>
                {userInfo.type === 'admin' || userInfo.type === 'viewer' ? <></> :<div className="row mb-2">
                    <div className="col-12 col-md-12 text-end">
                        <Link className="btn btn-success btn-sm rounded-0 me-1" to="add">
                            <i className="bi bi-person-plus"></i>&nbsp;&nbsp;Add Service Information
                        </Link>
                    </div>
                </div>}
                <h5 className="alert alert-primary rounded-0 p-2">Service Information List</h5>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered">
                        <thead>
                            <tr className='bg-secondary text-light'>
                                <th>Service Name</th>
                                <th>Service Type</th>
                                <th>Eligibility</th>
                                <th>Ineligibility</th>
                                <th>Selection Criteria</th>
                                <th>Selection Process</th>
                                <th>Is Handicapped?</th>
                                <th>IS Widow?</th>
                                <th width={'75px'}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {serviceInformationList.length > 0 ? <>
                                {serviceInformationList.map(sInfo => <tr key={sInfo.serviceId}>
                                    <td style={{'maxWidth': '450px'}}><span className="d-inline-block text-truncate" style={{'maxWidth': '450px'}}>{sInfo.serviceName}</span></td>
                                    <td>{sInfo.serviceType === 2 ? 'Non Training': 'Training'}</td>
                                    <td style={{'maxWidth': '450px'}}><span className="d-inline-block text-truncate" style={{'maxWidth': '150px'}}>{sInfo.eligibility}</span></td>
                                    <td style={{'maxWidth': '450px'}}><span className="d-inline-block text-truncate" style={{'maxWidth': '150px'}}>{sInfo.ineligibility}</span></td>
                                    <td style={{'maxWidth': '450px'}}><span className="d-inline-block text-truncate" style={{'maxWidth': '150px'}}>{sInfo.selectionCriteria}</span></td>
                                    <td style={{'maxWidth': '450px'}}><span className="d-inline-block text-truncate" style={{'maxWidth': '150px'}}>{sInfo.selectionProcess}</span></td>
                                    <td>{sInfo.handicappedFlag === 1 ? 'Yes' : sInfo.handicappedFlag === 2 ? 'No' : '--'}</td>
                                    <td>{sInfo.widowFlag === 1 ? 'Yes' : sInfo.widowFlag === 2 ? 'No' : '--'}</td>
                                    <td className='text-center fw-bold' width={'75px'}>
                                        {userInfo.type === 'admin' || userInfo.type === 'viewer' ? '' : <Link className='me-3' title="Edit Button" to={`edit/${sInfo.serviceId}`}><i className="bi bi-pencil-square"></i></Link>}
                                        <i className="bi bi-eye" title="View Detail Button" data-bs-toggle="modal" data-bs-target="#detailsInfo" onClick={() => setDetailsData(sInfo)}></i>
                                    </td>
                                </tr>
                                )}</> : <tr><td colSpan="9"><span className='text-danger'>Nothing Found!</span></td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                <TablePagination
                    component="div"
                    count={totalData}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <div className="modal fade" id="detailsInfo" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Service Information Details</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                {serviceInformationDetails !== null ? 
                                    <div>
                                        <table className="table table-bordered table-striped">
                                            <tbody>
                                                <tr>
                                                    <th>Age Limit</th>
                                                    <td>{serviceInformationDetails.ageLimit}</td>
                                                    <th width="200px">Annual Income Limit</th>
                                                    <td>{serviceInformationDetails.annualIncomeLimit !== '' ? serviceInformationDetails.annualIncomeLimit : '--'}</td>
                                                </tr>
                                                <tr>
                                                    <th>For</th>
                                                    <td>{serviceInformationDetails.gender === 1 ? 'Male' : serviceInformationDetails.gender === 2 ? 'Female' : serviceInformationDetails.gender === 4 ? 'All Gender' : 'Third Gender'}</td>
                                                    <th>Associate Profession</th>
                                                    <td>{professionList.length > 0 ? serviceInformationDetails.associateProfession !== null ? getAssiciatedProfessionName(serviceInformationDetails.associateProfession) : '--' : '--'}</td>
                                                </tr>
                                                <tr>
                                                    
                                                    <th>Is Handicapped?</th>
                                                    <td>{serviceInformationDetails.handicappedFlag === 1 ? 'Yes' : serviceInformationDetails.handicappedFlag === 2 ? 'No' : '-'}</td>
                                                    <th>Is Widow?</th>
                                                    <td>{serviceInformationDetails.widowFlag === 1 ? 'Yes' : serviceInformationDetails.widowFlag === 2 ? 'No' : '-'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Service Provider</th>
                                                    <td colSpan={3}>
                                                        {serviceInformationDetails.serviceProviderId !== null ? serviceInformationDetails.serviceProviderId.serviceProviderName : '--'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Service Name</th>
                                                    <td colSpan={3}>{serviceInformationDetails.serviceName}</td>
                                                </tr>
                                                <tr>
                                                    <th>Service Type</th>
                                                    <td colSpan={3}>{serviceInformationDetails.serviceType === 2 ? 'Non Training': 'Training'}</td>
                                                </tr>    
                                                <tr>
                                                    <th>Service Description</th>
                                                    <td colSpan={3} dangerouslySetInnerHTML={{__html: serviceInformationDetails.serviceDescription}}></td>
                                                </tr>
                                                <tr>
                                                    <th>Cancel Reason</th>
                                                    <td colSpan={3} dangerouslySetInnerHTML={{__html: serviceInformationDetails.cancelReason}}></td>
                                                    {/* <td colSpan={3}>{serviceInformationDetails.cancelReason}</td> */}
                                                </tr>
                                                <tr>
                                                    <th>Eligibility</th>
                                                    <td colSpan={3} dangerouslySetInnerHTML={{__html: serviceInformationDetails.eligibility}}></td>
                                                    {/* <td colSpan={3}>{serviceInformationDetails.eligibility}</td> */}
                                                </tr>
                                                <tr>
                                                    <th>Ineligibility</th>
                                                    <td colSpan={3} dangerouslySetInnerHTML={{__html: serviceInformationDetails.ineligibility}}></td>
                                                    {/* <td colSpan={3}>{serviceInformationDetails.ineligibility}</td> */}
                                                </tr>
                                                <tr>
                                                    <th>Selection Criteria</th>
                                                    <td colSpan={3} dangerouslySetInnerHTML={{__html: serviceInformationDetails.selectionCriteria}}></td>
                                                    {/* <td colSpan={3}>{serviceInformationDetails.selectionCriteria}</td> */}
                                                </tr>
                                                <tr>
                                                    <th>Selection Process</th>
                                                    <td colSpan={3} dangerouslySetInnerHTML={{__html: serviceInformationDetails.selectionProcess}}></td>
                                                    {/* <td colSpan={3}>{serviceInformationDetails.selectionProcess}</td> */}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                : "--"}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">
                                    <i className="bi bi-x-circle me-2"></i>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </> }
        </div>
    )
}