import { useEffect, useState } from 'react';
import useAuth from '../../Hooks/useAuth';
import { POST } from '../../Services/ApiService';
import { Link } from "react-router-dom";
import Loader from '../Common/Loader';

export default function Dashbaord(){

    const [loading, setLoading] = useState(true);
    const [counts, setCounts] =  useState({
        informationHub: 0,
        serviceProvider: 0,
        serviceInformation: 0,
        serviceSeeker: 0
    });
    const { userInfo } = useAuth();

    useEffect(() => {
        let hubId = null;
        if(userInfo.type !== 'admin' && userInfo.hubId != null){
            hubId = {infoHubId: userInfo.hubId}
        }else{
            hubId = {}
        }
        if(hubId !== null){
            POST('serviceProvider/search', hubId).then(response => {
                // console.log(response);
                if(response.status === 200 && response.data.code === 200){
                    setCounts(values => ({...values, serviceProvider: response.data.total}));
                }
            }).catch(err => {
                console.log(err)
            });

            if(userInfo.type === 'admin'){
                POST('informationHub/search', hubId).then(response => {
                    // console.log(response);
                    if(response.status === 200 && response.data.code === 200){
                        setCounts(values => ({...values, informationHub: response.data.total}));
                    }
                }).catch(err => {
                    console.log(err)
                });
            }

            POST('serviceInfo/search', {}).then(response => {
                // console.log(response);
                if(response.status === 200 && response.data.code === 200){
                    setCounts(values => ({...values, serviceInformation: response.data.total}));
                }
            }).catch(err => {
                console.log(err)
            });

            POST('serviceSeeker/search', hubId).then(response => {
                // console.log(response);
                if(response.status === 200 && response.data.code === 200){
                    setCounts(values => ({...values, serviceSeeker: response.data.total}));
                }
            }).catch(err => {
                console.log(err)
            });

            setLoading(false);
        }
    },[userInfo]);
    
    return(
        <div className="content">
            {loading ? <Loader/> :
                <div className="container">
                    <div className="row mt-5 mb-5">
                        <div className={`col-12 col-md-${userInfo.type === 'admin' ? '3' : '4'}`}>
                            <div className="card text-center mb-3">
                                <div className="card-header alert alert-primary fw-bold">
                                    Service Provider Count
                                </div>
                                <div className="card-body">
                                    <h1>{counts.serviceProvider}</h1>
                                </div>
                                <div className="card-footer">
                                    <Link to={'../settings/service-provider'}><button className="btn btn-outline-primary">Details<i className="bi bi-box-arrow-up-right ms-2"></i></button></Link>
                                </div>
                            </div>
                        </div>
                        <div className={`col-12 col-md-${userInfo.type === 'admin' ? '3' : '4'}`}>
                            <div className="card text-center mb-3">
                                <div className="card-header alert alert-primary fw-bold">
                                    Service Information Count
                                </div>
                                <div className="card-body">
                                    <h1>{counts.serviceInformation}</h1>
                                </div>
                                <div className="card-footer">
                                    <Link to={'../settings/service-information'}><button className="btn btn-outline-primary">Details<i className="bi bi-box-arrow-up-right ms-2"></i></button></Link>
                                </div>
                            </div>
                        </div>
                        { userInfo.type === 'admin' ?
                            <div className="col-12 col-md-3">
                                <div className="card text-center mb-3">
                                    <div className="card-header alert alert-primary fw-bold">
                                        Information Hub Count
                                    </div>
                                    <div className="card-body">
                                        <h1>{counts.informationHub}</h1>
                                    </div>
                                    <div className="card-footer">
                                        <Link to={'../settings/information-hub'}><button className="btn btn-outline-primary">Details<i className="bi bi-box-arrow-up-right ms-2"></i></button></Link>
                                    </div>
                                </div>
                            </div>
                        : ''}
                        <div className={`col-12 col-md-${userInfo.type === 'admin' ? '3' : '4'}`}>
                            <div className="card text-center mb-3">
                                <div className="card-header alert alert-primary fw-bold">
                                    Service Seeker Count
                                </div>
                                <div className="card-body">
                                    <h1>{counts.serviceSeeker}</h1>
                                </div>
                                <div className="card-footer">
                                    <Link to={'../service-seeker'}><button className="btn btn-outline-primary">Details<i className="bi bi-box-arrow-up-right ms-2"></i></button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}